import React from 'react';
import { Link } from 'react-router-dom';
import NavbarSubpageWESGS from "../../Layout/NavbarSubpageWESGS";
import { Col, Container, Row, Button } from "reactstrap";

const WelcomePage = () => {
  return (
    <React.Fragment>
      <NavbarSubpageWESGS/>
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center">
              <Col lg={6}>
                <div className="welcome-page">
                  <h1>Welcome to the World ESG Summit Travel & Commute Carbon Calculator</h1>
                  <p>
                  We’re excited to partner with c-mass to help measure the carbon footprint of your travel & commute. While carbon emissions are something we aim to reduce, the more details you provide, the more accurate your impact report will be. By sharing a few quick details, you’ll see how your travel & commute contribute to CO2 emissions—and how you can make a difference through simple, effective actions.
                  </p>
                  <div className="mt-4">
                    <Link to="/wesgs/attendee-contact" className="btn btn-primary mt-2">
                    Calculate My Emissions
                    </Link>
                    
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default WelcomePage;
