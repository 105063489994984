import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpageWESGS from "../../Layout/NavbarSubpageWESGS";
import { Col, FormGroup, Row, Container, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const CommuteInfo = () => {

    const { quizData, updateQuizData } = useContext(QuizContext);
    const [commutingType, setCommutingType] = useState(quizData.commutingType ? quizData.commutingType : '');
    const [commuteDistance, setCommuteDistance] = useState(0);

    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [totalCF, setTotalCF] = useState(quizData.totalCF);
    const navigate = useNavigate();
    const [totalQuestions, setTotalQuestions] = useState(4);
    const currentQuestion = 3;

    const emissionFactors = {
        car: 0.57,
        metro: 0.26,
        bus: 0.31,
        walk: 0, // Zero emissions
    };

    const handleNextClick = () => {

        updateQuizData({ commutingType, commuteDistance, totalCF: (parseFloat(totalCF) + parseFloat(carbonFootprint)), commuteCF: carbonFootprint });

        navigate('/wesgs/days-attending');

    };

    const handleBackClick = () => {
        navigate('/wesgs/travel-info');
    }

    const handleClickButton = (e) => {
        setCommutingType(e.target.value);
    }

    const handlecommutingType = (e) => {
        const CT = e.target.value;
        setCommutingType(e.target.value);
        if(commuteDistance) {
            calculateCommuteFootprint(CT, commuteDistance);
        }
    }

    const handleCommuteDistance = (e) => {
        const distance = parseFloat(e.target.value > 0 ? e.target.value : 0 );
        setCommuteDistance(distance);
        if (commutingType) {
            calculateCommuteFootprint(commutingType, distance);
        }
    };

    const calculateCommuteFootprint = (mode, distance) => {
        const factor = emissionFactors[mode] || 0;
        const footprint = factor * distance;
        setCarbonFootprint(footprint.toFixed(2) * 2); // Round to 2 decimal places
    };

    return (
        <React.Fragment>
            <NavbarSubpageWESGS />
            <section className="bg-home5" id="home">
                <div className="bg-overlay"></div>
                <Container>
                    <div className="position-relative" style={{ zIndex: "1" }}>
                        <div className="progress-wrapper sticky-top mb-3">
                            <div className="d-flex justify-content-between mb-1">
                                <span>{currentQuestion} / {totalQuestions}</span>
                            </div>
                            <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
                        </div>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <h2>How are you commuting to the venue?</h2>
                                <Form.Select
                                    id="commutingTypeSelect"
                                    value={commutingType}
                                    onChange={(e) => handlecommutingType(e)}
                                >
                                    <option value="">Select an option</option>
                                    <option value="car">Car</option>
                                    <option value="metro">Metro</option>
                                    <option value="bus">Bus</option>
                                    <option value="walk">Walk</option>
                                </Form.Select>
                                <FormGroup>
                                    <label htmlFor="commutingTypeSelect">How many kilometers is your one way trip?</label>
                                    <input
                                        type="number"
                                        value={commuteDistance}
                                        onChange={(e) => handleCommuteDistance(e)}
                                        className="form-control"
                                        placeholder="Trip distance in KG"
                                    />
                                </FormGroup>

                                <div className="mt-2">
                                    <button onClick={handleBackClick} className="btn btn-primary me-4">
                                        Back
                                    </button>
                                    <button onClick={handleNextClick} disabled={!commutingType} className="btn btn-primary">
                                        Next
                                    </button>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="metric-detail">

                                    <h3>Your Carbon Footprint:</h3>
                                    <h4>{((parseFloat(totalCF) + parseFloat(carbonFootprint))).toFixed(2)} kg CO₂e</h4>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col lg={6}>
                            <div className="me-4">
                                <h6 className="sub-title mb-1"><i class="bi bi-question-circle"></i>Why are we asking this?</h6>
                                <p className="text-muted fs-17">
                                    Your mode of commute and the distance of your trip will directly impact your carbon footprint.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default CommuteInfo;
