import React, { useState } from "react";
import { Col, Container, Row, Form, Input, FormGroup, Button } from "reactstrap";
import { API_ENDPOINTS } from "../frameworks/basic-rest/api-endpoints";
import http from "../frameworks/basic-rest/http";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/ContactSection.css"; // Ensure you create this CSS file

// Import background 3D object
import object3D from "../assets/images/cross.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    comments: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await http.post(API_ENDPOINTS.INQUIRY, formData);
      toast.success("Email sent successfully!");

      setFormData({
        name: "",
        email: "",
        number: "",
        comments: "",
      });
    } catch (error) {
      toast.error("Failed to send email. Please try again.");
    }
  };

  return (
    <section className="contact-section" id="contact">
      <Container>
        <Row className="align-items-center">
          {/* Contact Form */}
          <Col lg={6}>
            <h2 className="contact-title">Contact Us</h2>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  name="name"
                  type="text"
                  className="contact-input"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  className="contact-input"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="number"
                  className="contact-input"
                  placeholder="Phone Number"
                  value={formData.number}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  name="comments"
                  className="contact-input message-box"
                  placeholder="Message"
                  value={formData.comments}
                  onChange={handleChange}
                />
              </FormGroup>
              <Button type="submit" className="contact-submit-btn" style={{ borderRadius: '20px', padding: '6px 14px', marginRight: '5px' }}>
                Submit
              </Button>
            </Form>
          </Col>

          {/* 3D Object on the Right */}
          <Col lg={6} className="d-flex justify-content-center">
            <img src={object3D} alt="3D Object" className="contact-object" style={{width: '400px'}}/>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </section>
  );
};

export default Contact;
