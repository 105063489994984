import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import UserContext from "../../contexts/user/UserContext";
import "../../assets/css/HomeSection.css";

// استيراد الصور الخلفية
import star from "../../assets/images/star.png";
import cyl from "../../assets/images/cyl.png";
import arch from "../../assets/images/arch.png";

const Section = () => {
  const { user } = useContext(UserContext);

  return (
    <section className="home-section" style={{ direction: "rtl" }}>
      {/* الصور الخلفية */}
      <img src={star} alt="نجمة" className="bg-image star" style={{right: '600px'}}/>
      <img src={cyl} alt="أسطوانة" className="bg-image cyl" />
      <img src={arch} alt="قوس" className="bg-image arch" />

      <Container>
        <Row className="align-items-center">
          <Col lg={6} className="text-content">
            <h1>
              دمج إجراءات المناخ في <span className="bold">منتجاتك</span>
            </h1>
            <p className="description">
              توفر c-mass حلاً فريداً لتقدير البصمة الكربونية وبرنامجاً للوصول إلى أرصدة الكربون، مما 
              يتيح التكامل السلس عبر واجهة برمجة التطبيقات لمنتجاتك لتجربة مخصصة وفعالة.
            </p>
            <div className="button-group">
              {user ? (
                <Link to="/profile/home" className="btn btn-primary">
                  الذهاب إلى لوحة التحكم
                </Link>
              ) : (
                <>
                  <Button color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px', marginRight: '5px' }}>
                    <Link to="/signup" style={{ color: 'white' }}>التسجيل</Link>
                  </Button>
                  <Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>
                    <Link to="/login" style={{ color: 'black' }}>تسجيل الدخول</Link>
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>

      </Container>
    </section>
  );
};

export default Section;
