import React from "react";
import { Col, Container, Row } from "reactstrap";

// Import partner logos
import carbonOffset from "../assets/images/feature/CO_certification_logo.jpg";
import saudiLogo from "../assets/images/feature/saudi-logo.png";
import vision2030 from "../assets/images/feature/vision2030.png";
import esgSummit from "../assets/images/feature/esg-summit.png";
import cyl from "../assets/images/cyl-croped.png";
import circle from "../assets/images/circle.png";
import "../assets/css/Services.css";

// Import icons
// import emissionsIcon from "../assets/images/feature/emissions-icon.png";
// import streamlineIcon from "../assets/images/feature/streamline-icon.png";
// import engageIcon from "../assets/images/feature/engage-icon.png";

const Services = () => {
  return (
    <section id="process" className="services-section">
      <img src={cyl} alt="Cylinder" className="bg-image cyl-services"/>
      <img src={circle} alt="Cylinder" className="bg-image circle-services" />
      <Container>
        {/* Main Title */}
        <Row className="justify-content-center text-center">
          <Col lg={10}>
            <h2>
              Comply with The Kingdom's <span className="bold">2030</span> Vision of
              Reducing Our Collective CO₂ Emissions
            </h2>
            <p className="text-muted mt-3">
            We combine the latest scientific advancements with a carefully curated portfolio of verified carbon offset projects, ensuring minimal risk and maximum climate impact. Backed by top global agencies, our approach guarantees that every contribution supports credible and impactful environmental initiatives—all at an affordable price.
            </p>
          </Col>
        </Row>

        {/* Partner Logos */}
        <Row className="justify-content-center mt-4">
          <Col xs={6} sm={3} className="text-center">
            <img src={carbonOffset} alt="Carbon Offset" className="partner-logo" style={{ height: '100px' }} />
          </Col>
          <Col xs={6} sm={3} className="text-center">
            <img src={saudiLogo} alt="Saudi Vision" className="partner-logo" style={{ height: '100px' }} />
          </Col>
          <Col xs={6} sm={3} className="text-center">
            <img src={vision2030} alt="Vision 2030" className="partner-logo" style={{ height: '100px' }} />
          </Col>
          <Col xs={6} sm={3} className="text-center">
            <img src={esgSummit} alt="ESG Summit" className="partner-logo" style={{ height: '100px' }} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Services;
