import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Card, CardBody } from 'reactstrap';
import ProjectsSupportedCard from '../../components/ProjectsSupportedCard';
import UserContext from "../../contexts/user/UserContext";
import { ModeContext } from '../../contexts/mode/ModeContext';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/offset.css"; // Create and import your CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthAmericas, faReceipt, faPagelines, faTree, faCar, faDownload, faPlane, faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Spinner from "react-bootstrap/Spinner";
import logo from '../../assets/images/nz-logo.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ct from '../../assets/images/ct.png';
import QRCode from 'qrcode';
import OxfordCategoriesChart from '../../components/OxfordCategoriesChart';
import ProjectTypesChart from '../../components/ProjectTypesChart';
import CountriesChart from '../../components/CountriesChart';

const Offset = () => {
    const { user } = useContext(UserContext);
    const { isTestMode } = useContext(ModeContext);
    const [orders, setOrders] = useState([]);
    const [ordersStat, setOrdersStat] = useState([]);
    const [projects, setProjects] = useState([]);
    const navigate = useNavigate();
    const { company } = useOutletContext();
    const [loading, setLoading] = useState(true);


    const generateCertificate = async (company, orders) => {

        try {
            const projectIds = orders?.lastOrderDetails?.projectRecords.map(record => record.projectId);
            if (!projectIds || projectIds.length === 0) {
                console.error('No projects found');
                return;
            }
            // Fetch project records by their IDs

            // Extract project IDs from the project records

            // Fetch projects using the project IDs
            const projectsResponse = await http.post(`${API_ENDPOINTS.PROJECTS_BY_IDS}?isTest=${isTestMode}`, { projectIds });

            // Store the project details
            setProjects(projectsResponse.data.projects);

            const doc = new jsPDF();

            // Load the background image (optional)
            const img = new Image();
            img.src = ct;

            let imgWidth, imgHeight;

            // Wait for the image to load
            await new Promise(resolve => {
                img.onload = () => {
                    // Set image dimensions
                    imgWidth = img.width;
                    imgHeight = img.height;

                    // Set the PDF page size to match the image dimensions
                    doc.internal.pageSize.width = imgWidth * 0.264583; // Convert px to mm
                    doc.internal.pageSize.height = imgHeight * 0.264583;

                    // Add the image, centering it on the page
                    doc.addImage(img, 'PNG', 0, 0, imgWidth * 0.264583, imgHeight * 0.264583);

                    resolve();
                };
            });

            doc.setFont('Helvetica');
            doc.setFontSize(100);
            doc.setTextColor('#222222');

            doc.text(`${orders.lastOrderDetails.createdOn.split('T')[0]}`, 250, 58, { align: "center" });
            doc.text(`${company.name}`, 120, 338, { align: "center" });
            doc.text(`${orders.lastOrderDetails.creditsPurchased} kg of CO₂e`, 180, 465, { align: "center" });

            let projs_name = '';
            let projs_type = '';
            let proj_registry = '';
            let proj_location = '';

            projectsResponse.data.projects.forEach((project, index) => {
                if ((index + 1) == projectsResponse.data.projects.length) {
                    projs_name += `${project.name}`;
                    projs_type += `${project.type}`;
                    proj_registry += `${project.carbonRegistry.name}`;
                    proj_location += `${project.location}`;
                }
                else {
                    projs_name += `${project.name}, `;
                    projs_type += `${project.type}, `;
                    proj_registry += `${project.carbonRegistry.name}, `;
                    proj_location += `${project.location}, `;
                }

            });
            doc.text(`${projs_name}`, 70, 595, { align: "left" });
            doc.text(`${projs_type}`, 70, 725, { align: "left" });
            doc.text(`${proj_registry}`, 840, 725, { align: "left" });
            doc.text(`${proj_location}`, 70, 855, { align: "left" });
            doc.text(`c-mass`, 840, 855, { align: "left" });

            doc.setFont('Helvetica', 'bold');
            doc.setFontSize(200);
            doc.setTextColor('#2cc9a2');
            doc.text(`${orders.lastOrderDetails.creditsPurchased}`, 565, 945, { align: "center" });

            doc.setFont('Helvetica', 'bold');
            doc.setFontSize(80);
            doc.setTextColor('#0096FF');
            doc.textWithLink(`${orders.lastOrderDetails.orderNumber}`, 215, 990, { url: 'http://www.google.com' });

            doc.setTextColor('#222222');
            doc.text(`${proj_registry}`, 250, 1030, { align: "left" });

            // Generate the QR code and add it to the PDF 
            const qrDataUrl = await QRCode.toDataURL(`https://c-mass.co/order-detail/${orders.lastOrderDetails._id}`, { width: 100 });
            doc.setFont('Helvetica', 'bold');
            doc.setFontSize(15);
            doc.setTextColor('#222222');



            // Position text and QR code to fit within the image dimensions
            doc.addImage(qrDataUrl, 'PNG', imgWidth * 0.5 * 0.364583 - 12.5, imgHeight * 0.85 * 0.240783, 190, 190);

            // Add a new page if this is not the last code

            // Save the PDF with all promo codes in one file
            doc.save('cc--certificate.pdf.pdf');

        } catch (error) {

        }

    };

    const handleAPIDocsClick = () => {
        window.open("https://c-mass.mintlify.app/introduction", "_blank");
    };

    const generateImpactReportPDF = () => {
        const pdf = new jsPDF("p", "pt", "a4");
        const pageWidth = pdf.internal.pageSize.getWidth();
        let y = 40; // Starting y-position for the header

        // Logo (Top-Left Corner)
        const logoWidth = 50;
        const logoHeight = 50;
        const logoX = 40;
        const logoY = 20;
        pdf.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);

        // Adjust y-position based on the logo height
        y = logoY + logoHeight + 20;

        // Header Section
        pdf.setFont('Helvetica', 'bold');
        pdf.setFontSize(28);
        pdf.setTextColor('#222222');
        pdf.text("Impact Report", pageWidth / 2, y, { align: "center" });
        y += 35;

        pdf.setFontSize(18);
        pdf.setTextColor('#555555');
        pdf.text(company.name, pageWidth / 2, y, { align: "center" });
        y += 25;

        pdf.setFontSize(14);
        pdf.setTextColor('#000000');
        // pdf.text("September 6 - November 5, 2024", pageWidth / 2, y, { align: "center" });
        y += 10;

        // Main Card: Total Carbon Credits Purchased
        const cardWidth = 180;
        const cardHeight = 130;
        const cardX = 210;
        const cardY = y;

        pdf.setFillColor('#c9efe4');
        pdf.roundedRect(cardX, cardY, cardWidth, cardHeight, 10, 10, 'F');

        const textX = cardX + 15;
        let textY = cardY + 20;

        pdf.setFontSize(8);
        pdf.setTextColor('#888888');
        pdf.text("Total amount of carbon \noffseted", textX, textY);
        textY += 25;
        textY += 20;
        pdf.setFontSize(32);
        pdf.setTextColor('#000000'); // Black color for the number
        pdf.text(`${orders?.totalCreditsPurchased || 0}`, textX, textY);
        textY += 25;

        pdf.setFontSize(14);
        pdf.setTextColor('#888888');
        pdf.text("kg", textX + 5, textY);

        // Move to the next section
        y += cardHeight + 30;

        // Secondary Metrics Section
        const metrics = [
            { title: "New trees planted", value: `${(ordersStat?.totalCreditsPurchased * 0.022).toFixed(2)}` },
            { title: "Cars off the road for a year", value: `${((ordersStat?.totalCreditsPurchased) * 0.00021).toFixed(2)}` },
            // { title: "Homes' annual energy use", value: `${((orders?.totalCreditsPurchased) * 0.00022).toFixed(2)}` },
            { title: "Flights from RUH to LHR", value: `${((ordersStat?.totalCreditsPurchased) * 0.0011).toFixed(2)}` },
        ];

        // Position and spacing for each metric card
        const metricCardWidth = 160;
        const metricCardHeight = 50;
        const metricStartX = cardX + cardWidth + 20; // Start right after the main card
        let metricX = metricStartX;
        let metricY = cardY;

        // metrics.forEach((metric, index) => {
        //     // Draw each metric box with background color
        //     pdf.setFillColor('#F9F3EA'); // Light beige background color
        //     pdf.roundedRect(metricX, metricY, metricCardWidth, metricCardHeight, 5, 5, 'F');

        //     // Metric Title
        //     pdf.setFontSize(8);
        //     pdf.setTextColor('#888888');
        //     pdf.text(metric.title, metricX + 10, metricY + 15);

        //     // Metric Value
        //     pdf.setFontSize(18);
        //     pdf.setTextColor('#333333');
        //     pdf.text(metric.value, metricX + 10, metricY + 35);

        //     // Position the next box
        //     if (index % 2 === 0) {
        //         metricX += metricCardWidth + 10; // Move to the right for the next column
        //     } else {
        //         metricX = metricStartX; // Reset x position
        //         metricY += metricCardHeight + 10; // Move down for the next row
        //     }
        // });

        // Disclaimer text
        // pdf.setFontSize(6);
        // pdf.setTextColor('#999999');
        // pdf.text("* calculations based on GCOM and ICAO formulas", metricStartX + 5, metricY + 8);

        // Perform Calculations for Each Section
        const calculateCategoryData = (orders) => {
            const categoryMap = {};
            orders?.forEach(order => {
                order.projectRecords.forEach(record => {
                    const categoryName = record.projectCategoryId?.name || "Unknown Category";
                    const credits = Math.abs(record.delta);
                    categoryMap[categoryName] = (categoryMap[categoryName] || 0) + credits;
                });
            });
            const totalCredits = Object.values(categoryMap).reduce((acc, curr) => acc + curr, 0);
            return Object.keys(categoryMap).map(categoryName => ({
                label: categoryName,
                percentage: ((categoryMap[categoryName] / totalCredits) * 100).toFixed(1)
            }));
        };

        const calculateCountryData = (orders) => {
            const countryMap = {};
            orders?.forEach(order => {
                order.projectRecords.forEach(record => {
                    const country = record.projectId?.location || 'Unknown Country';
                    const credits = Math.abs(record.delta);
                    countryMap[country] = (countryMap[country] || 0) + credits;
                });
            });
            const totalCredits = Object.values(countryMap).reduce((acc, curr) => acc + curr, 0);
            return Object.keys(countryMap).map(country => ({
                label: country,
                percentage: ((countryMap[country] / totalCredits) * 100).toFixed(1)
            }));
        };

        const calculateProjectTypeData = (orders) => {
            const projectTypeMap = {};
            orders?.forEach(order => {
                order.projectRecords.forEach(record => {
                    const projectType = record.projectId?.type || 'Unknown Type';
                    const credits = Math.abs(record.delta);
                    projectTypeMap[projectType] = (projectTypeMap[projectType] || 0) + credits;
                });
            });
            const totalCredits = Object.values(projectTypeMap).reduce((acc, curr) => acc + curr, 0);
            return Object.keys(projectTypeMap).map(projectType => ({
                label: projectType,
                percentage: ((projectTypeMap[projectType] / totalCredits) * 100).toFixed(1)
            }));
        };

        const calculateProjectData = (orders) => {
            const projectMap = {};
            orders?.forEach(order => {
                order.projectRecords.forEach(record => {
                    const projectName = record.projectId?.name || 'Unknown Project';
                    const credits = Math.abs(record.delta);
                    projectMap[projectName] = (projectMap[projectName] || 0) + credits;
                });
            });
            const totalCredits = Object.values(projectMap).reduce((acc, curr) => acc + curr, 0);
            return Object.keys(projectMap).map(project => ({
                label: project,
                percentage: ((projectMap[project] / totalCredits) * 100).toFixed(1)
            }));
        };

        // Calculated Data for Each Section
        const oxfordCategories = calculateCategoryData(ordersStat.orders);
        const countries = calculateCountryData(ordersStat.orders);
        const projectTypes = calculateProjectTypeData(ordersStat.orders);
        const projects = calculateProjectData(ordersStat.orders);

        // Colors for Dots and Bars
        const colors = ['#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B'];

        // Render Section Function
        const renderSection = (title, data, startX, startY) => {
            pdf.setFontSize(16);
            pdf.setTextColor('#222222');
            pdf.setFont('Helvetica', 'bold');
            pdf.text(title, startX, startY);
            startY += 20;

            data.forEach((item, index) => {
                pdf.setFillColor(colors[index % colors.length]);
                pdf.circle(startX + 5, startY + 7, 4, 'F'); // Color dot

                pdf.setFontSize(8);
                pdf.setTextColor('#333333');
                pdf.text(item.label, startX + 15, startY + 10);

                pdf.setTextColor('#333333');
                pdf.text(`${item.percentage}%`, startX + 150, startY + 10);

                // Progress Bar
                //pdf.setFillColor(colors[index % colors.length]);
                //pdf.roundedRect(startX + 2, startY + 15, (item.percentage * 1.5), 8, 2, 2, 'F');

                startY += 30;
            });
            return startY + 10; // Return the new y-position after rendering
        };

        // Render Oxford Categories, Countries, Project Types, Projects in Two Columns
        const leftColumnX = 40;
        const rightColumnX = pageWidth / 2 + 20;
        let currentY = y;
        let rightColumnY = y;

        rightColumnY = renderSection("Projects", projects, rightColumnX, rightColumnY);
        currentY = renderSection("Project Types", projectTypes, leftColumnX, currentY);


        rightColumnY = renderSection("Countries", countries, rightColumnX, rightColumnY);
        currentY = renderSection("Categories", oxfordCategories, leftColumnX, currentY);

        // Function to calculate unique sustainability goals images
        const getUniqueSustainableDevelopmentGoals = (orders) => {
            const uniqueImages = new Set();
            orders.forEach(order => {
                order.projectRecords.forEach(record => {
                    record.projectId.sustainableDevelopment?.forEach(url => {
                        uniqueImages.add(url)
                    });
                });
            });
            return Array.from(uniqueImages); // Convert the Set back to an Array for rendering
        };

        // Render Sustainable Development Goals Section
        const renderSustainableDevelopmentGoals = (pdf, y, uniqueSDGImages) => {
            pdf.setFontSize(16);
            pdf.setTextColor('#222222');
            pdf.setFont('Helvetica', 'bold');
            pdf.text("Sustainable Development Goals", pageWidth / 2, y, { align: "center" });
            y += 20;

            const imageWidth = 35;
            const imageHeight = 35;
            const padding = 10; // Padding between images
            let x = 40; // Starting x-position for images
            uniqueSDGImages.forEach((url) => {
                if (x + imageWidth > pageWidth - 40) { // Move to the next line if out of space
                    x = 40;
                    y += imageHeight + padding;
                }
                pdf.addImage(url, 'JPEG', x, y, imageWidth, imageHeight);
                x += imageWidth + padding;
            });

            return y + imageHeight + 20; // Return the new y-position after rendering
        };

        // Get unique sustainability goal images
        const uniqueSDGImages = getUniqueSustainableDevelopmentGoals(ordersStat.orders);

        // Render the Sustainable Development Goals section at the bottom
        let finalYPosition = Math.max(currentY, rightColumnY);
        finalYPosition = renderSustainableDevelopmentGoals(pdf, finalYPosition, uniqueSDGImages);


        // Save the PDF
        pdf.save("Impact_Report.pdf");
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersResponse = await http.get(`${API_ENDPOINTS.ORDERS_FULFILLED}/${company._id}?isTest=${isTestMode}`);
                setOrdersStat(ordersResponse.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                setOrdersStat([]);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [user, company._id, isTestMode]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const ordersResponse = await http.get(`${API_ENDPOINTS.ORDERS_SUMMERY}/${company._id}?isTest=${isTestMode}`);
                setOrders(ordersResponse.data);

            } catch (error) {
                console.error("Error fetching data:", error);
                setOrders([]);
            } finally {
                setLoading(false);
            }
        };



        fetchData();
    }, [user, company._id, isTestMode]);

    const handlePurchaseClick = () => {
        navigate('/profile/purchase');
    }

    const handleCertificate = () => {
        generateCertificate(company, orders);
    }

    const handleImpactClick = () => {
        navigate('/profile/my-impact', { state: { orders } });
    }

    const handleOrderDetailClick = () => {
        navigate(`/profile/order/${orders?.lastOrderDetails?._id}`);
    }

    const handleViewAllOrdersClick = () => {
        navigate(`/profile/orders`);
    }

    const handleMetricClick = () => {
        navigate('/profile/my-impact')
    }

    console.log('orders.monthOverMonthDifference',orders.monthOverMonthDifference)

    return (
        <Container className="dashboard">
            {/* <Button color="primary" onClick={handlePurchaseClick} style={{ borderRadius: '10px' }}>Purchase credits</Button> */}

            <Row className="mb-4">
                <Col>
                    <button color="primary" className="purchase-credits-btn" onClick={generateImpactReportPDF}><FontAwesomeIcon icon={faDownload} /> Download Report</button>
                </Col>
            </Row>
            {/* <hr style={{ border: '1px solid #000', width: '90%', margin: '10px auto' }} /> */}
            <Row>
                <Col md="3">
                    <Card className="info-card same-size-card card" style={{ borderRadius: '20px', border: 'none', padding: '15px' }}>
                        <OxfordCategoriesChart orders={ordersStat.orders}/>
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="info-card same-size-card card" style={{ borderRadius: '20px', border: 'none', padding: '15px' }}>
                        <ProjectTypesChart orders={ordersStat.orders} />
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="info-card same-size-card card" style={{ borderRadius: '20px', border: 'none', padding: '15px' }}>
                        <CountriesChart orders={ordersStat.orders} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6} md={6} sm={12} className="mb-4">
                    <Card className="info-card same-size-card card" style={{ borderRadius: '10px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                        {loading ? <Spinner /> : (
                            <CardBody>
                                <div className="orders-header">
                                    <p><FontAwesomeIcon icon={faReceipt} size="lg" />
                                        <span className="orders-text">Fulfilled Orders</span></p>
                                </div>
                                <h1 className="order-count">{orders.totalOrderAmount ? orders.totalOrderAmount : 0}</h1>

                                {/* Most Recent Order Section */}
                                {orders?.lastOrderDetails ? <div className="recent-order-section">
                                    <h6 className="recent-order-title">Most Recent</h6>
                                    <Row>
                                        <Col xs={20}>
                                            <p><span className="label">Price:</span> SAR {(orders?.lastOrderDetails?.price_sar_halalas / 100).toFixed(2)}</p>
                                            <p><span className="label">CO₂e:</span> {orders?.lastOrderDetails?.creditsPurchased} kg</p>
                                            <p><span className="label">Placed On:</span> {orders?.lastOrderDetails?.createdOn.split('T')[0]}</p>
                                            {/* <Button color="link" className="view-details-btn" onClick={handleOrderDetailClick}>View details</Button>
                                            <Button color="link" className="view-certificate-btn" onClick={handleCertificate}>View certificate</Button> */}
                                        </Col>
                                        {/*<Col xs={4}>
                            <img 
                                src="/path-to-certificate-image.png" 
                                alt="Certificate"
                                className="certificate-image" 
                            />
                        </Col>*/}
                                    </Row>
                                </div> :
                                    <div className="recent-order-section">
                                        <h6 className="recent-order-title">No Orders</h6>

                                    </div>}
                                <button className="purchase-credits-btn" onClick={handleViewAllOrdersClick}>All Orders</button>
                            </CardBody>
                        )}
                    </Card>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12} className="mb-4">
                    <Card className="info-card same-size-card card" style={{ borderRadius: '10px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none', minWidth: '50px' }}>
                        {loading ? <Spinner /> : (
                            <CardBody>
                                <div className="icon-text">
                                    <p className="total-description"><FontAwesomeIcon icon={faCloudArrowDown} /> Purchased carbon credits</p>
                                </div>
                                <h1 className="impact-value">
                                    {orders?.totalCreditsPurchased ? orders?.totalCreditsPurchased.toFixed(2) : 0} kg
                                </h1>

                                {/* Month-over-Month Difference Display */}
                                {orders?.monthOverMonthDifference && orders?.monthOverMonthDifference != 0 && (
                                    <div className="month-over-month">
                                        <span style={{
                                            color: orders.monthOverMonthDifference > 0 ? 'green' : orders.monthOverMonthDifference < 0 ? 'red' : 'grey',
                                            fontWeight: 'bold',
                                            fontSize: '16px'
                                        }}>
                                            {orders.monthOverMonthDifference > 0 ? '▲' : orders.monthOverMonthDifference < 0 ? '▼' : '➖'}
                                            {Math.abs(orders.monthOverMonthDifference).toFixed(2)}%
                                        </span>
                                        <span className="month-label"> compared to last month</span>
                                    </div>
                                )}

                                {/* Sustainable Development Goals (SDGs) Display */}
                                {orders?.sustainableDevelopment && orders.sustainableDevelopment.length > 0 && (
                                    <div className="sdg-container">
                                        <h6>Sustainable Development Goals:</h6>
                                        <div className="sdg-badges">
                                            {orders.sustainableDevelopment.map((sdgUrl, index) => (
                                                <img key={index} src={sdgUrl} alt={`SDG-${index}`} className="sdg-badge" />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </CardBody>
                        )}
                    </Card>
                </Col>


            </Row>
            <Row>
                {/* <Col lg={4} md={6} sm={12} className="mb-4">
                    <Card className="info-card same-size-card card" style={{ borderRadius: '10px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                        <CardBody>
                            <div className="icon-text">
                                <p className="sh"><FontAwesomeIcon size="xl" icon={faBullhorn} style={{ color: "#e05910" }} />
                                    <span style={{ fontSize: 'x-large', marginLeft: '5px', fontWeight: 'bold' }}>Share</span></p>
                            </div>
                            <p>Tell the world about your climate impact.</p>
                            <button className="small-button" onClick={handleImpactClick}>View impact page</button>
                            <button className="small-button">Settings</button>
                        </CardBody>
                    </Card>
                </Col> */}

                {/* <Col lg={4} md={6} sm={12} className="mb-4">
                    <Card className="api-card same-size-card-bottom card" style={{ borderRadius: '10px', boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)', border: 'none' }}>
                        <CardBody>
                            <h3>c-mass API</h3>
                            <p>Complete the API setup to begin using the live API.</p>
                            <Button color="link" className="api-docs-btn" onClick={handleAPIDocsClick}>Explore the API documentation.</Button>
                        </CardBody>
                    </Card>
                </Col> */}
                {/* <Col lg={4} md={6} sm={12} className="mb-4">
                    <ProjectsSupportedCard />
                </Col> */}
            </Row>
        </Container>
    );
};

export default Offset;
