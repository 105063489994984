import React, { useState, useEffect } from 'react';
import { Table, Spinner, Container } from 'reactstrap';
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import { useParams } from 'react-router-dom';

const UserDetailsPage = () => {
    const { userId } = useParams(); // Get the user ID from URL
    const [user, setUser] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchUserDetails();
    }, [userId]);

    const fetchUserDetails = async () => {
        try {
            const userResponse = await http.get(`${API_ENDPOINTS.USERS}/${userId}`);
            setUser(userResponse.data.user);
            setCompanies(userResponse.data.companies);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user details:', error);
            setError('Failed to fetch user details.');
            setLoading(false);
        }
    };

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <Container className="dashboard">
            <h2>User Details</h2>
            {user && (
                <div>
                    <p><strong>Email:</strong> {user.email}</p>
                </div>
            )}
            <h3>Associated Companies</h3>
            <Table striped>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Location</th>
                        <th>Contact Info</th>
                        <th>Unit Price</th>
                        <th>Payment Setup</th>
                        <th>Payment Setup Status</th>
                        <th>User Type</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        <tr key={companies._id}>
                            <td>{companies.name}</td>
                            <td>{companies.location}</td>
                            <td>
                                <div>Email: {companies.contactInfo.email}</div>
                                <div>Phone: {companies.contactInfo.phone}</div>
                            </td>
                            <td>{companies.unitPrice} SAR</td>
                            <td>{companies.paymentSetup ? 'YES' : 'NO'}</td>
                            <td>{companies.paymentInfo ? companies.paymentInfo.status : 'not setup'}</td>
                            <td>{companies.accountType ? companies.accountType : 'CM_REGULAR'}</td>
                        </tr>
                    }
                </tbody>
            </Table>
        </Container>
    );
};

export default UserDetailsPage;
