import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Button, Spinner } from 'reactstrap';
import http from '../../frameworks/basic-rest/http';
import { API_ENDPOINTS } from '../../frameworks/basic-rest/api-endpoints';
import { ModeContext } from '../../contexts/mode/ModeContext';

const OrderConfirmation = () => {
    const { orderId } = useParams(); // Get orderId from URL params
    const [order, setOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
  const { isTestMode } = useContext(ModeContext);

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await http.get(`${API_ENDPOINTS.ORDER_DETAILS}/${orderId}?isTest=${isTestMode}`);
                setOrder(response.data.order);
                setLoading(false);
            } catch (err) {
                setError('Failed to retrieve order details.');
                setLoading(false);
            }
        };

        fetchOrder();
    }, [orderId]);

    if (loading) {
        return (
            <Container className="text-center" style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Spinner color="primary" />
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="text-center" style={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <p>{error}</p>
                <Button color="primary" tag={Link} to="/profile/orders">
                    Back to Orders
                </Button>
            </Container>
        );
    }

    return (
        <Container className="dashboard">
            <Row className="justify-content-center">
                <Col>
                    <h2 className="text-success text-center">Order confirmed!</h2>
                    <Card className="mt-4" style={{ borderRadius: '20px' }}>
                        <CardBody>
                            <h4 className="text-center mb-4">Order number: <strong>{order.orderNumber}</strong></h4>
                            <div className="d-flex justify-content-between mb-2">
                                <span>Portfolio</span>
                                <span>{order.portfolio?.name}</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <span>CO₂e amount</span>
                                <span>{(order?.kg_amount / 1000)} tonnes</span>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                                <span>Unit price</span>
                                <span>SAR {order.price_sar_halalas/100?.toFixed(2) / (order?.kg_amount/1000)} / tonne</span>
                            </div>
                            <hr />
                            {/* <div className="d-flex justify-content-between mb-2">
                                <strong>Total</strong>
                                <strong>SAR {order.price_sar_halalas/100?.toFixed(2)}</strong>
                            </div> */}
                            {/* <div className="d-flex justify-content-between mb-2">
                                <span>Free credits</span>
                                <span>-SAR {order.freeCredits?.toFixed(2)}</span>
                            </div> */}
                            <hr />
                            <div className="d-flex justify-content-between">
                                <strong>Order Total</strong>
                                <strong>SAR {(order.price_sar_halalas/100)?.toFixed(2)}</strong>
                            </div>
                            <p className="text-center mt-4">
                                Thanks for placing an order! View <Link to="/profile/orders">your order history</Link> for more details about your past orders, including what projects they’ve supported.
                            </p>
                            <Button color="primary" block tag={Link} to="/profile/home" style={{ borderRadius: '10px' }}>
                                Back to dashboard
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default OrderConfirmation;
