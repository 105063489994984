import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, button } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import '../../assets/css/PortfolioDetails.css';
import { useParams, useNavigate } from "react-router-dom";
import { ModeContext } from '../../contexts/mode/ModeContext';

const PortfolioDetails = () => {
  const [portfolio, setPortfolio] = useState(null);
  const [projects, setProjects] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');
  const [loading, setLoading] = useState(true);
  const { portfolioId } = useParams();
  const navigate = useNavigate();
  const { isTestMode } = useContext(ModeContext);

  useEffect(() => {
    const fetchPortfolioDetails = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.PORTFOLIO}/${portfolioId}?isTest=${isTestMode}`);
        const { portfolio, projects } = response.data;
        setPortfolio(portfolio);
        setProjects(projects);

        // Get unique categories
        const uniqueCategories = [...new Set(projects.map(proj => proj.projectCategory?.name))];
        setCategories(uniqueCategories);
        setActiveCategory(uniqueCategories[0]); // Default to first category
        setLoading(false);
      } catch (error) {
        console.error("Error fetching portfolio details:", error);
      }
    };

    fetchPortfolioDetails();
  }, [portfolioId, isTestMode]);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };

  const handleProjectClick = (projectId) => {
    navigate(`/profile/project/${projectId}`);
  };

  return (
    <Container className="dashboard">
      {loading ? <Spinner /> : (
        <>
          {/* Portfolio Header */}
          <Row className="portfolio-header">
            <Col md="12">
              <h1 className="portfolio-title">{portfolio?.name}</h1>
            </Col>
          </Row>

          {/* Category Filter buttons */}
          <Row className="category-buttons">
            <Col md="12" className="d-flex justify-content-end">
              {categories.map((category, index) => (
                <button
                  key={index}
                  className={`category-button ${activeCategory === category ? 'active' : ''}`}
                  onClick={() => handleCategoryClick(category)}
                >
                  {category}
                </button>
              ))}
            </Col>
          </Row>


          {/* Project Cards */}
          <Row className="projects-grid">
            {projects
              .filter((project) => project.projectCategory?.name === activeCategory)
              .map((project, idx) => (
                <Col md="4" sm="6" xs="12" key={idx}>
                  <Card className="project-card" onClick={() => handleProjectClick(project._id)} style={{ borderRadius: '20px' }}>
                    <img className="project-image" src={project.image || '/default-image.jpg'} />
                    <CardBody>
                      <CardTitle tag="h5">{project?.name}</CardTitle>
                      <CardText className="project-description">
                        {project.description.length > 100
                          ? `${project.description.slice(0, 100)}...`
                          : project.description}
                      </CardText>

                      <p className="project-developer"><strong>Developer:</strong> {project.developer}</p>
                      <div className="project-footer">
                        <button className="view-details">View Details</button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
          </Row>
        </>
      )}
    </Container>
  );
};

export default PortfolioDetails;
