import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import NavbarSubpage from "../../../Layout/NavbarSubpage";
import { Container, Row, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import UserContext from '../../../contexts/user/UserContext';
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import Cookies from 'js-cookie';
import Spinner from 'react-bootstrap/Spinner';
import { GoogleLogin } from '@react-oauth/google';

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { signIn } = useContext(UserContext);
  const [passwordError, setPasswordError] = useState("");
  const [companyName, setCompanyName] = useState('');
  const [name, setName] = useState('');
  const [clocation, setCLocation] = useState('');
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false); // Loading state
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });

  const from = location.state?.from || "/";

  const handlePasswordChange = (e) => {
    const inputPassword = e.target.value;
    setPassword(inputPassword);

    // Update password criteria as the user types
    setPasswordCriteria({
      length: inputPassword.length >= 8,
      lowercase: /[a-z]/.test(inputPassword),
      uppercase: /[A-Z]/.test(inputPassword),
      number: /\d/.test(inputPassword),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(inputPassword),
    });
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    // Check if at least 3 out of 5 criteria are met
    const metCriteria = Object.values(passwordCriteria).filter(Boolean).length;
    if (metCriteria < 3) {
      setPasswordError("Password must meet at least 3 of the 5 requirements.");
      return;
    }

    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }

    try {
      setLoading(true); // Set loading to true when request starts

      const res = await http.post(API_ENDPOINTS.SIGNUP, {
        email,
        password,
        companyName,
        clocation,
        name
      });

      // Handle successful registration
      if (res.status === 201) {
        const { user, token } = res.data;
        signIn(user);
        Cookies.set('auth_token', token, { expires: 7 });
        navigate('/profile/home');
      }
    } catch (err) {
      setError(err.response?.data?.message || "Signup failed.");
    }
    finally {
      setLoading(false); // Set loading to false when request is complete
    }
  };

  const responseMessage = (response) => {
    console.log(response);
  };
  const errorMessage = (error) => {
    console.log(error);
  };

  return (
    <React.Fragment>
      <NavbarSubpage />
      <section className="bg-home5" id="home">
        <div className="bg-overlay"></div>
        <Container>
          <div className="position-relative" style={{ zIndex: "1" }}>
            <Row className="align-items-center justify-content-center">
              <Col lg={6}>
                <h2 className="text-center mb-4">Signup</h2>
                <Form>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="company-name">Name</Label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      placeholder="Name"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="company-name">Company Name</Label>
                    <input
                      type="text"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="form-control"
                      placeholder="Company Name"
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="company-location">Location</Label>
                    <input
                      type="text"
                      value={clocation}
                      onChange={(e) => setCLocation(e.target.value)}
                      className="form-control"
                      placeholder="Location"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="password">Password</Label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={handlePasswordChange}
                      required
                    />
                    <div className="password-requirements mt-2">
                      <p>Your password must contain:</p>
                      <ul>
                        <li style={{ color: passwordCriteria.length ? 'green' : 'black' }}>At least 8 characters</li>
                        <li style={{ color: passwordCriteria.lowercase ? 'green' : 'black' }}>Lower case letters (a-z)</li>
                        <li style={{ color: passwordCriteria.uppercase ? 'green' : 'black' }}>Upper case letters (A-Z)</li>
                        <li style={{ color: passwordCriteria.number ? 'green' : 'black' }}>Numbers (0-9)</li>
                        <li style={{ color: passwordCriteria.specialChar ? 'green' : 'black' }}>Special characters (e.g., !@#$%^&*)</li>
                      </ul>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="confirmPassword">Confirm Password</Label>
                    <Input
                      type="password"
                      name="confirmPassword"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    {passwordError && <div className="error-message">{passwordError}</div>}
                    {error && <div className="error-message">{error}</div>}
                  </FormGroup>
                  <Button type="submit" onClick={handleSignup} color="primary" block disabled={loading} style={{ borderRadius: '20px', padding: '6px 14px' }}>
                    {loading ? <Spinner size="sm" /> : 'Signup'}
                  </Button>
                </Form>
                <div className="text-center mt-3">
                  <p>Already have an account? <Link to="/login">Login</Link></p>
                </div>
                <div className="text-center mt-4">
                  <p>
                    By using c-mass, you agree to the <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default SignupPage;
