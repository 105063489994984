import React from "react";
import "../../assets/css/CompanyPage.css";
import NavbarSubpage from "../../Layout/NavbarSubpage";
import Team from "../../components/Team";
import cyl from "../../assets/images/cyl.png";
import arch from "../../assets/images/arch.png";
const CompanyPage = () => {
    return (
        <React.Fragment>
            <NavbarSubpage />
            <section className="bg-home5 company-page" id="home">
                <img src={cyl} alt="Cylinder" className="bg-image cyl" style={{top: '20px'}}/>
                <img src={arch} alt="Arch" className="bg-image arch" style={{bottom: '700px'}}/>
                {/* About Us Section */}
                <section className="about-us-section">
                    <div className="about-us-content">
                        <h1>
                            Empowering businesses to take meaningful action for a{" "}
                            <span className="highlight">sustainable future</span>
                        </h1>
                        <p>
                            We believe that everyone has a role to play in reducing the carbon
                            footprint and addressing climate change. However, navigating the world
                            of carbon credits can be complex and overwhelming, especially for those
                            new to the space. That’s why we’ve created an easy and transparent
                            platform that simplifies the process.
                        </p>
                        <p>
                            Our platform provides businesses with access to verified and impactful
                            carbon credits, enabling them to offset emissions and contribute to
                            global sustainability goals. By combining simplicity with robust science,
                            we’re helping companies of all sizes take action and create a tangible
                            climate impact.
                        </p>
                    </div>
                </section>

                {/* Team Section */}
                <Team />

            </section>
        </React.Fragment>
    );
};

export default CompanyPage;
