import React, { useState, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, Button, Alert } from 'reactstrap';
import WESGSPaymentComponent from '../../components/WESGSPaymentComponent';
import { useNavigate } from 'react-router-dom';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import { ModeContext } from '../../contexts/mode/ModeContext';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpageWESGS from "../../Layout/NavbarSubpageWESGS";

const SummaryPage = () => {
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
    const { quizData, updateQuizData } = useContext(QuizContext);

    const price = (quizData.tavelCF + (quizData.commuteCF * parseFloat(quizData.numberOfDays)) )* 0.08 < 20 ? 20 : ((quizData.tavelCF + (quizData.commuteCF * parseFloat(quizData.numberOfDays))) )* 0.08;

    console.log('price', price);
    console.log((quizData.tavelCF + (quizData.commuteCF * parseFloat(quizData.numberOfDays)) )* 0.08)
    console.log((quizData.tavelCF + (quizData.commuteCF * parseFloat(quizData.numberOfDays)) )* 0.08 < 20)

    const kg_amount = (quizData.tavelCF + (quizData.commuteCF * parseFloat(quizData.numberOfDays))).toFixed(2);
    let conpanyId = process.env.REACT_APP_WESGS_ID;


    if (!quizData) {
        return <p>Error: Your data is missing. Please go back and try again.</p>;
    }

    return (
        <React.Fragment>
            <NavbarSubpageWESGS />
            <section className="bg-home5" id="home">
                <div className="bg-overlay"></div>
                <Container fluid className="dashboard">
                    <Row className="justify-content-center">
                        <Col md="6" lg="5">
                            <h2 className="mt-4">Your Total Carbon Footprint</h2>

                            <Card style={{ borderRadius: '15px', marginTop: '20px' }}>
                                <CardBody>
                                    <div className="d-flex justify-content-between">
                                        <span>Carbon Emissions</span>
                                        <span>{(quizData.tavelCF + (quizData.commuteCF * quizData.numberOfDays)).toFixed(2)} kg</span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span>Description</span>
                                        <span>To offset my World ESG Summit travel & commute</span>
                                    </div>
                                    <hr />
                                    <div className="d-flex justify-content-between">
                                        <strong>Order total</strong>
                                        <strong>SAR {price.toFixed(2)}</strong>
                                    </div>
                                    <Row>
                                        <Col md="6" lg="5">
                                            <button className='btn btn-primary'>Send it to my email</button>
                                        </Col>
                                    </Row>
                                    <>
                                        <h4 className="mt-4">Payment method</h4>
                                        <WESGSPaymentComponent price={price} name={quizData.name}kg_amount={kg_amount} email={quizData.email}/>
                                    </>

                                    {error && <Alert color="danger" className="mt-3">{error}</Alert>}
                                    {success && <Alert color="success" className="mt-3">{success}</Alert>}
                                    <p className="text-center mt-3">
                                        By paying, you agree to the <a href="/terms">Terms of Service</a> and{' '}
                                        <a href="/privacy">Privacy Policy</a>.
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default SummaryPage;
