import React, { useState } from "react";
import "./PricingPage.css";
import NavbarSubpageAR from "../../Layout/NavbarSubpageAR";
import { useNavigate } from "react-router-dom";
import PricingAR from '../../components/PricingAR.js';

const PricingPageAR = () => {
    const [faqOpen, setFaqOpen] = useState({});
    const navigate = useNavigate();

    const toggleFaq = (index) => {
        setFaqOpen((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleGetStarted = () => {
        navigate("/login");
    };

    return (
        <>
            <NavbarSubpageAR />
            <div className="pricing-page" dir="rtl">
            <PricingAR />
            </div>
        </>
    );
};

export default PricingPageAR;
