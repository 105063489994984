import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Table, Card, CardBody, Button } from "reactstrap";
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas, faFile,faExclamation, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import UserContext from "../../contexts/user/UserContext";
import { ModeContext } from "../../contexts/mode/ModeContext";
import { useNavigate, useOutletContext } from "react-router-dom";
import "../../assets/css/OrdersPage.css"; // Add custom styling here

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(UserContext);
  const { isTestMode } = useContext(ModeContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersResponse = await http.get(`${API_ENDPOINTS.ORDERS}/${user.companyId}?isTest=${isTestMode}`);
        setOrders(ordersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setOrders([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, isTestMode]);

  const getStatusBadgeClass = (status) => {
    switch (status.toLowerCase()) {
      case 'placed':
        return 'bg-warning text-dark'; // Yellow badge for placed
      case 'canceled':
        return 'bg-danger'; // Red badge for canceled
      case 'fulfilled':
        return 'bg-success'; // Green badge for fulfilled
      default:
        return 'bg-secondary'; // Default badge if status is unknown
    }
  };

  const handleOrderClick = (orderId) => {
    navigate(`/profile/order/${orderId}`);
  };

  return (
    <Container className="dashboard">
      <h2 className="orders-title">Orders</h2>

      {/* Summary Cards */}
      <Row className="orders-summary">
        <Col md="3" sm="6">
          <card className="summary-card">
            <CardBody className="summary-card-body">
              <div className="summary-icon-circle">
                <FontAwesomeIcon icon={faFile} size="lg" className="summary-icon" />
              </div>
              <div className="summary-text">
                <p>Orders</p>
                <h3 className="summary-value">{orders.totalOrderAmount ? orders.totalOrderAmount : "0"}</h3>
              </div>
            </CardBody>
          </card>
        </Col>

        <Col md="3" sm="6">
          <card className="summary-card">
            <CardBody className="summary-card-body">
              <div className="summary-icon-circle">
                <FontAwesomeIcon icon={faEarthAmericas} size="lg" className="summary-icon" />
              </div>
              <div className="summary-text">
                <p>CO₂e Offset</p>
                <h3 className="summary-value">{orders.totalCreditsPurchased ? `${orders.totalCreditsPurchased.toFixed(0)}kg` : "0kg"}</h3>
              </div>
            </CardBody>
          </card>
        </Col>
      </Row>


      {/* Orders Table */}
      <Row>
        <Col>
          <div className="orders-table-container">
            <Table className="orders-table" responsive>
              <thead>
                <tr>
                  <th>Order Id</th>
                  <th>Status</th>
                  <th>Amount</th>
                  <th>Description</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      <Spinner />
                    </td>
                  </tr>
                ) : orders.orders?.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      No orders found.
                    </td>
                  </tr>
                ) : (
                  orders.orders?.map((order) => (
                    <tr key={order._id}>
                      <td>
                        <Button color="link" className="order-id" onClick={() => handleOrderClick(order._id)}>
                          {order._id}
                        </Button>
                      </td>
                      <td>
                        <div className="status-cell">
                          <span className={`status-icon ${order.status.toLowerCase()}`}>
                            {order.status.toLowerCase() === "fulfilled" && (
                              <span className="status-circle fulfilled">
                                <FontAwesomeIcon icon={faCheck} />
                              </span>
                            )}
                            {order.status.toLowerCase() === "canceled" && (
                              <span className="status-circle canceled">
                                <FontAwesomeIcon icon={faTimes} />
                              </span>
                            )}
                            {order.status.toLowerCase() === "placed" && (
                              <span className="status-circle placed">
                                <FontAwesomeIcon icon={faExclamation} />
                              </span>
                            )}
                          </span>

                          <span className="status-text">{order.status}</span>
                        </div>
                      </td>
                      <td>{order.kg_amount || 0} kg</td>
                      <td>{order.description}</td>
                      <td>{new Date(order.createdAt).toLocaleString()}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

    </Container>
  );
};

export default OrdersPage;
