import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/ProductsSection.css"; // تأكد من إنشاء ملف CSS المناسب

// استيراد أيقونات المنتجات
import airlines from "../assets/images/icons/tickets.png";
import ecommerce from "../assets/images/icons/order.png";
import logistics from "../assets/images/icons/shipping.png";

// استيراد الصور الخلفية
import arch from "../assets/images/arch.png";
import cyl from "../assets/images/cyl.png";

// بيانات المنتجات
const productsData = [
  {
    image: airlines,
    title: "تذاكر الطيران المحايدة للكربون",
    description:
      "وفر لعملائك خيار شراء تذاكر طيران محايدة للكربون عبر دمج حلول تعويض الانبعاثات مباشرةً في منصتك.",
  },
  {
    image: ecommerce,
    title: "توصيل الطلبات محايد للكربون",
    description:
      "اجعل عمليات الشحن الخاصة بك أكثر استدامة عن طريق تعويض انبعاثات الكربون لكل طلب يتم شحنه.",
  },
  {
    image: logistics,
    title: "الخدمات اللوجستية المحايدة للكربون",
    description:
      "ساعد شركات النقل والخدمات اللوجستية في تقديم خدمات خالية من الكربون لعملائها من خلال التكامل مع واجهاتنا البرمجية.",
  },
];

const ProductsSection = () => {
  return (
    <section className="products-section">
      {/* الصور الخلفية */}
      <img src={arch} alt="شكل قوسي" className="bg-image arch" />
      <img src={cyl} alt="اسطوانة" className="bg-image cyl" />

      <Container>
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            <h2 className="section-title">المنتجات التي يمكنك استخدامها عبر واجهاتنا البرمجية</h2>
            <p className="section-description">
              وفر لعملائك حلولًا مستدامة وسهلة لخفض انبعاثات الكربون عبر تكامل سلس مع أنظمتك.
            </p>
          </Col>
        </Row>

        <Row className="products-grid">
          {productsData.map((product, index) => (
            <Col lg={4} md={6} sm={12} className="product-box" key={index}>
              <div className="product-card">
                <img src={product.image} alt={product.title} className="product-image" />
                <h4 className="product-title">{product.title}</h4>
                <p className="product-description">{product.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ProductsSection;
