import React, { useState } from "react";
import "./PricingPage.css";
import NavbarSubpage from "../../Layout/NavbarSubpage";
import { useNavigate } from "react-router-dom";
import Pricing from '../../components/Pricing.js';

const PricingPage = () => {
    const [faqOpen, setFaqOpen] = useState({});
    const navigate = useNavigate();

    const toggleFaq = (index) => {
        setFaqOpen((prev) => ({ ...prev, [index]: !prev[index] }));
    };

    const handleGetStarted = () => {
        navigate("/login");
    };

    return (
        <>
            <NavbarSubpage />
            <div className="pricing-page">
                <Pricing />
            </div>
        </>
    );
};

export default PricingPage;
