import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';

const CountriesChart = ({ orders }) => {
    // Step 1: Calculate the total credits purchased for each country
    const countryMap = {};

    orders?.forEach(order => {
        order.projectRecords?.forEach(record => {
            const country = record.projectId?.location || 'Unknown Country';
            const credits = Math.abs(record.delta);

            if (countryMap[country]) {
                countryMap[country] += credits;
            } else {
                countryMap[country] = credits;
            }
        });
    });

    // Step 2: Calculate the total credits purchased
    const totalCredits = Object.values(countryMap).reduce((acc, curr) => acc + curr, 0);

    // Step 3: Calculate the percentage for each country
    const countryData = Object.keys(countryMap).map(country => ({
        country,
        percentage: ((countryMap[country] / totalCredits) * 100).toFixed(1)
    }));

    const colors = ['#4CAF50', '#8BC34A', '#CDDC39', '#FFEB3B']; // Different shades for variety

    return (
        <div className="countries">
            <h5 className="stat-title"><FontAwesomeIcon icon={faEarthAmericas} style={{color: '#008565'}}/> Countries</h5>
            <div className="stat-chart">
                {countryData.length > 0 ? countryData.map((data, index) => (
                    <div key={index} className="country-bar" style={{ marginBottom: '8px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div 
                                className="color-dot" 
                                style={{ 
                                    width: '12px', 
                                    height: '12px', 
                                    borderRadius: '50%', 
                                    backgroundColor: colors[index % colors.length], 
                                    marginRight: '8px' 
                                }} 
                            />
                            <span className="country-name">{data.country}</span>
                            <div className="percentage" style={{ marginLeft: 'auto', marginRight: '8px' }}>{data.percentage}%</div>
                        </div>
                        {/* <div 
                            className="progress-bar" 
                            style={{ 
                                width: `${data.percentage}%`, 
                                backgroundColor: colors[index % colors.length], 
                                height: '8px', 
                                borderRadius: '4px',
                                marginTop: '4px' 
                            }} 
                        /> */}
                    </div>
                )) : 'No Data'}
            </div>
        </div>
    );
};

export default CountriesChart;
