import React, { useEffect, useState, useContext } from "react";
import { Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Row, Col, Label, Container, FormGroup } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import { ModeContext } from '../../contexts/mode/ModeContext';
import '../../assets/css/subaccounts.css';

const Subaccounts = () => {
    const [subaccounts, setSubaccounts] = useState(null);
    const [newSubaccount, setNewSubaccount] = useState({
        name: '',
        id: '',
        customConfigs: false,
        percentageOffset: '',
        maxPrice: '',
        maxKG: ''
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [detailModalOpen, setDetailModalOpen] = useState(false);
    const [selectedSubaccount, setSelectedSubaccount] = useState(null);
    const [loading, setLoading] = useState(true);
    const { isTestMode } = useContext(ModeContext);

    const toggleModal = () => setModalOpen(!modalOpen);
    const toggleDetailModal = () => setDetailModalOpen(!detailModalOpen);

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setNewSubaccount({ ...newSubaccount, [name]: type === "checkbox" ? checked : value });
    };

    useEffect(() => {
        const fetchsubaccounts = async () => {
            try {
                const response = await http.get(`${API_ENDPOINTS.GET_SUBACCOUNTS}?isTest=${isTestMode}`);

                setSubaccounts(response.data.subaccounts);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching subaccounts:", error);
            }
        };

        fetchsubaccounts();
    }, [isTestMode]);

    const handleAddSubaccount = async () => {
        try {
            const newAccount = {
                name: newSubaccount.name,
                id: newSubaccount.id,
                customConfigs: newSubaccount.customConfigs,
                percentageOffset: newSubaccount.customConfigs ? newSubaccount.percentageOffset : null,
                maxPrice: newSubaccount.customConfigs ? newSubaccount.maxPrice : null,
                maxKG: newSubaccount.customConfigs ? newSubaccount.maxKG : null,
            };

            const response = await http.post(`${API_ENDPOINTS.CREATE_SUBACCOUNTS}?isTest=${isTestMode}`, newAccount);

            if (response.status === 201) {
                setSubaccounts([...subaccounts, response.data.subaccount]);
                setNewSubaccount({ name: '', id: '', customConfigs: false, percentageOffset: '', maxPrice: '', maxKG: '' });
                toggleModal();
            }
        } catch (error) {
            console.error('Error creating subaccount:', error);
            alert('Failed to create subaccount. Please try again.');
        }
    };

    const viewDetails = (subaccount) => {
        setSelectedSubaccount(subaccount);
        toggleDetailModal();
    };

    return (
        <div className="dashboard">
            {loading ? <Container><Spinner /></Container> : (<Container>
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h2>Subaccounts</h2>
                    <Button color="primary" onClick={toggleModal} style={{ borderRadius: '30px', padding: '6px 14px' }}>+ Add Subaccount</Button>
                </div>

                <Table className="subaccounts-table" responsive style={{ borderRadius: '20px' }}>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Created On</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subaccounts.map((subaccount) => (
                            <tr key={subaccount.id}>
                                <td>{subaccount.id}</td>
                                <td>{subaccount.name}</td>
                                <td>{subaccount.createdAt.split('T')[0]}</td>
                                <td>
                                    <Button color="link" onClick={() => viewDetails(subaccount)}>
                                        View Details
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

                {/* Add Subaccount Modal */}
                <Modal isOpen={modalOpen} toggle={toggleModal}>
                    <ModalHeader toggle={toggleModal}>Add New Subaccount</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Label for="name">Name</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    value={newSubaccount.name}
                                    onChange={handleInputChange}
                                    placeholder="Enter name"
                                />
                            </Col>
                            <Col md={12} className="mt-3">
                                <Label for="id">ID</Label>
                                <Input
                                    type="text"
                                    name="id"
                                    value={newSubaccount.id}
                                    onChange={handleInputChange}
                                    placeholder="Enter ID"
                                />
                            </Col>
                            <Col md={12} className="mt-3">
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="checkbox"
                                            name="customConfigs"
                                            checked={newSubaccount.customConfigs}
                                            onChange={handleInputChange}
                                        />{' '}
                                        Add Custom Configurations
                                    </Label>
                                </FormGroup>
                            </Col>
                            {newSubaccount.customConfigs && (
                                <>
                                    <Col md={12} className="mt-3">
                                        <Label for="percentageOffset">Percentage to Offset</Label>
                                        <Input
                                            type="number"
                                            name="percentageOffset"
                                            value={newSubaccount.percentageOffset}
                                            onChange={handleInputChange}
                                            placeholder="Enter percentage to offset"
                                        />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                        <Label for="maxPrice">Max Price</Label>
                                        <Input
                                            type="number"
                                            name="maxPrice"
                                            value={newSubaccount.maxPrice}
                                            onChange={handleInputChange}
                                            placeholder="Enter max price"
                                        />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                        <Label for="maxKG">Max KG</Label>
                                        <Input
                                            type="number"
                                            name="maxKG"
                                            value={newSubaccount.maxKG}
                                            onChange={handleInputChange}
                                            placeholder="Enter max KG"
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ borderRadius: '30px', padding: '6px 14px' }} color="primary" onClick={handleAddSubaccount}>
                            Create Subaccount
                        </Button>
                        <Button style={{ borderRadius: '30px', padding: '6px 14px' }} color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* Subaccount Detail Modal */}
                <Modal isOpen={detailModalOpen} toggle={toggleDetailModal}>
                    <ModalHeader toggle={toggleDetailModal}>
                        Subaccount {selectedSubaccount?.id}
                    </ModalHeader>
                    <ModalBody>
                        <p><strong>Name:</strong> {selectedSubaccount?.name}</p>
                        <p><strong>ID:</strong> {selectedSubaccount?.id}</p>
                        <p><strong>Created On:</strong> {selectedSubaccount?.createdAt.split('T')[0]}</p>
                        <p><strong>Percentage:</strong> {selectedSubaccount?.percentageOffset}%</p>
                        <p><strong>Max Price:</strong> {selectedSubaccount?.maxPrice ? `${selectedSubaccount.maxPrice} SAR` : "Not set"}</p>
                        <p><strong>Max KG:</strong> {selectedSubaccount?.maxKG ? `${selectedSubaccount.maxKG} KG` : "Not set"}</p>
                    </ModalBody>

                    <ModalFooter>
                        <Button color="secondary" style={{ borderRadius: '30px', padding: '6px 14px' }} onClick={toggleDetailModal}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </Container>)}
        </div>
    );
};

export default Subaccounts;
