// src/pages/ChoosePortfolio.js
import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import '../../assets/css/choosePortfolio.css';
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from 'react-router-dom';
import { ModeContext } from '../../contexts/mode/ModeContext';

const ChoosePortfolio = () => {
    const [portfolios, setPortfolios] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { isTestMode } = useContext(ModeContext);

    useEffect(() => {
        const fetchPortfolios = async () => {
            try {
                const portfolioResponse = await http.get(`${API_ENDPOINTS.PORTFOLIO}?isTest=${isTestMode}`);
                setPortfolios(portfolioResponse.data.portfolios);
            } catch (error) {
                console.error("Error fetching portfolios:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchPortfolios();
    }, [isTestMode]);

    const handlePortfolioClick = (portfolioId) => {
        navigate(`/profile/portfolio/${portfolioId}`);
    };

    return (
        <Container fluid className="dashboard">
            <h1>Choose a Portfolio</h1>
            {loading ? (
                <Spinner />
            ) : (
                <div className="portfolio-list">
                    {portfolios?.map((portfolio) => (
                        <Card
                            key={portfolio._id}
                            className="portfolio-card d-flex flex-row align-items-stretch"
                            onClick={() => handlePortfolioClick(portfolio._id)}
                            style={{borderRadius: '20px'}}
                        >
                            {/* Image Section (Left Side) */}
                            <div
                                className="portfolio-image"
                                style={{ backgroundImage: `url(${portfolio.img})` }}
                            ></div>

                            {/* Text Section (Right Side) */}
                            <CardBody className="portfolio-text">
                                <CardTitle tag="h3">{portfolio.name}</CardTitle>
                                <CardText>{portfolio.description}</CardText>
                            </CardBody>
                        </Card>
                    ))}
                </div>
            )}
        </Container>

    );
};

export default ChoosePortfolio;
