import React from "react";
import "../../assets/css/CompanyPage.css";
import NavbarSubpageAR from "../../Layout/NavbarSubpageAR";
import Team from "../../components/Team";
import cyl from "../../assets/images/cyl.png";
import arch from "../../assets/images/arch.png";

const CompanyPage = () => {
    return (
        <React.Fragment>
            <NavbarSubpageAR />
            <section className="bg-home5 company-page" id="home" dir="rtl"> {/* تفعيل RTL للغة العربية */}
                <img src={cyl} alt="أسطوانة" className="bg-image cyl" style={{top: '20px'}}/>
                <img src={arch} alt="قوس" className="bg-image arch" style={{bottom: '700px'}}/>

                {/* قسم من نحن */}
                <section className="about-us-section">
                    <div className="about-us-content">
                        <h1>
                            تمكين الشركات لاتخاذ إجراءات هادفة نحو{" "}
                            <span className="highlight">مستقبل مستدام</span>
                        </h1>
                        <p>
                            نحن نؤمن بأن لكل شخص دورًا يلعبه في تقليل البصمة الكربونية ومعالجة تغير المناخ. 
                            ولكن، يمكن أن يكون عالم أرصدة الكربون معقدًا ومربكًا، خاصة لأولئك الجدد في هذا المجال.
                            لهذا السبب قمنا بإنشاء منصة سهلة وشفافة لتبسيط العملية.
                        </p>
                        <p>
                            توفر منصتنا للشركات إمكانية الوصول إلى أرصدة كربونية موثوقة وفعالة،
                            مما يتيح لها تعويض الانبعاثات والمساهمة في أهداف الاستدامة العالمية.
                            من خلال الجمع بين البساطة والعلم الدقيق، نساعد الشركات من جميع الأحجام على اتخاذ إجراءات ملموسة وإحداث تأثير بيئي واضح.
                        </p>
                    </div>
                </section>

                {/* قسم الفريق */}
                <Team />

            </section>
        </React.Fragment>
    );
};

export default CompanyPage;
