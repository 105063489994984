import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/ConsultingServices.css"; // تأكد من إنشاء ملف CSS هذا

// استيراد الأيقونات/الصور
import carbonIcon from "../assets/images/icons/footprint.png";
import ghgIcon from "../assets/images/icons/ghg.png";
import lifecycleIcon from "../assets/images/icons/lifecycle.png";
import roadmapIcon from "../assets/images/icons/roadmap.png";
import offsetIcon from "../assets/images/icons/carboncredits.png";
import disclosureIcon from "../assets/images/icons/disclosure.png";
import arch from "../assets/images/arch.png";
import cyl from "../assets/images/cyl.png";

const servicesData = [
  {
    icon: carbonIcon,
    title: "تحليل البصمة الكربونية",
    description:
      "قياس وتحليل البصمة الكربونية لمنظمتك. تحديد المصادر الرئيسية للانبعاثات وتطوير استراتيجيات عملية للحد من انبعاثات ثاني أكسيد الكربون.",
  },
  {
    icon: ghgIcon,
    title: "جرد الغازات الدفيئة وخط الأساس",
    description:
      "إعداد جرد شامل لانبعاثات الغازات الدفيئة. تتبع الانبعاثات ووضع خط أساس موثوق لإعداد تقارير الاستدامة.",
  },
  {
    icon: lifecycleIcon,
    title: "تحليل دورة الحياة وتقييم التأثير",
    description:
      "تقييم التأثير البيئي لمنتجاتك وخدماتك طوال دورة حياتها. تنفيذ حلول صديقة للبيئة.",
  },
  {
    icon: roadmapIcon,
    title: "تطوير خارطة طريق للحياد الصفري",
    description:
      "إنشاء خارطة طريق استراتيجية لتحقيق الحياد الكربوني. تنفيذ استراتيجيات واقعية ومدعومة بالعلوم لتحقيق أهداف الاستدامة.",
  },
  {
    icon: offsetIcon,
    title: "تعويض الكربون المعتمد",
    description:
      "تعويض انبعاثاتك من خلال شراء أرصدة كربونية معتمدة. الاستثمار في مشاريع تساهم في العمل المناخي والاستدامة.",
  },
  {
    icon: disclosureIcon,
    title: "الإفصاح عن إجراءات العمل المناخي",
    description:
      "ضمان الامتثال للأطر العالمية للاستدامة. الإبلاغ بشفافية عن جهود خفض الكربون والتأثير البيئي.",
  },
];

const ConsultingServicesAr = () => {
  return (
    <section className="consulting-services" dir="rtl">
      {/* عناصر الخلفية */}
      <img src={arch} alt="قوس" className="bg-image arch" />
      <img src={cyl} alt="أسطوانة" className="bg-image cyl" />

      <Container>
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            <h2 className="section-title">خدمات الاستشارات</h2>
          </Col>
        </Row>

        <Row className="services-grid">
          {servicesData.map((service, index) => (
            <Col lg={4} md={6} sm={12} className="service-box" key={index}>
              <div className="service-card">
                <img src={service.icon} alt={service.title} className="service-icon" />
                <h4 className="service-title">{service.title}</h4>
                <p className="service-description">{service.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ConsultingServicesAr;
