import React, { useState, useEffect } from "react";
import { Container, Navbar, NavbarBrand, Nav, NavItem, NavLink, Button,   NavbarToggler,
  Collapse, } from "reactstrap";
import { Link } from "react-router-dom";

// Import images
import logoLight from "../assets/images/nz-logo.png";
import logoDark from "../assets/images/nz-logo-dk.png";

const NavBar = ({ isDark }) => {
  const [navClass, setNavClass] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    const scrollUp = document.documentElement.scrollTop;
    setNavClass(scrollUp > 50 ? "nav-sticky" : "");
  }

  return (
    <Navbar expand="lg" className={`custom-navbar ${navClass}`} style={{
      background: 'linear-gradient(to bottom, white,rgb(243 255 250))', /* White to Light Green */
      borderBottom: '1px solid rgba(0, 0, 0, 0.1);' /* Optional: Subtle border */
    }}>
      <Container className="d-flex justify-content-between align-items-center">

        {isMobile ? (
          <>
          <NavbarBrand className="center-logo">
          <Link to="/">
            <img src={isDark ? logoDark : logoLight} alt="Logo" className="nav-logo" style={{ height: "80px" }} />
          </Link>
        </NavbarBrand>
            <NavbarToggler onClick={() => setIsOpen(!isOpen)} className="ms-auto">
              <span className="navbar-toggler-icon"></span>
            </NavbarToggler>
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ms-auto d-flex flex-column text-center" navbar>
                <NavItem>
                  <NavLink href="/ar" className="nav-item-link">
                    <Button outline color="primary" className="rotate-button" style={{ borderRadius: "20px", padding: "6px 14px" }}>AR</Button>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#process" className="nav-item-link">
                    <Button outline color="primary" className="rotate-button" style={{ borderRadius: "20px", padding: "6px 14px" }}>Process</Button>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#pricing" className="nav-item-link">
                    <Button outline color="primary" className="rotate-button" style={{ borderRadius: "20px", padding: "6px 14px" }}>Pricing</Button>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#blog" className="nav-item-link">
                    <Button outline color="primary" className="rotate-button" style={{ borderRadius: "20px", padding: "6px 14px" }}>Blog</Button>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/terms" className="nav-item-link">
                    <Button outline color="primary" className="rotate-button" style={{ borderRadius: "20px", padding: "6px 14px" }}>Terms</Button>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#contact" className="contact-button">
                    <Button outline color="primary" className="rotate-button" style={{ borderRadius: "20px", padding: "6px 14px" }}>Contact Us</Button>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </>
        ) : (
          <>{/* Left Menu Items */}
            <Nav className="d-flex align-items-center">
              <NavItem>
                <NavLink href="/ar" className="nav-item-link"><Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>AR</Button></NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#process" className="nav-item-link"><Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>Process</Button></NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#pricing" className="nav-item-link"><Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>Pricing</Button></NavLink>
              </NavItem>
            </Nav>

            {/* Center Logo */}
            <NavbarBrand className="center-logo">
              <Link to="/">
                <img src={isDark ? logoDark : logoLight} alt="Logo" className="nav-logo" style={{ height: '100px' }} />
              </Link>
            </NavbarBrand>

            {/* Right Menu Items */}
            <Nav className="d-flex align-items-center">
              <NavItem>
                <NavLink href="#blog" className="nav-item-link"><Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>Blog</Button></NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/terms" className="nav-item-link"><Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>Terms</Button></NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#contact" className="contact-button"><Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>Contact Us</Button></NavLink>
              </NavItem>
            </Nav>
          </>
        )}
      </Container>
    </Navbar>

  );
};

export default NavBar;
