// WESGSPaymentCallback.js
import React, { useEffect, useContext, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner, Container, Row, Col, Alert } from 'reactstrap';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import { QuizContext } from '../../contexts/quiz/QuizContext';

const WESGSPaymentCallback = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { quizData, clearQuizData } = useContext(QuizContext);

    const [statusMessage, setStatusMessage] = useState("Processing your payment...");
    const [isLoading, setIsLoading] = useState(true);
    const hasSubmitted = useRef(false); // Ref to prevent multiple submissions

    const query = new URLSearchParams(location.search);
    const paymentId = query.get('id');
    const status = query.get('status');
    const companyId = query.get('companyId');
    const kg_amount = query.get('kg_amount');
    const price = query.get('price');
    const name = query.get('name');
    const email = query.get('email');

    useEffect(() => {
        if (!quizData || !status || !paymentId  || hasSubmitted.current) {
            console.log("Waiting for required data...");
            return; // Exit early if data isn't available yet
        }
        hasSubmitted.current = true; 
        const placeOrderWithPaymentId = async () => {
            if (status === 'paid' && paymentId) {
                try {
                    const finalOrderData = {
                        companyId: companyId,
                        customer: name,
                        email: email,
                        paymentId: paymentId,
                        status,
                        kg_amount: kg_amount,
                        price: price
                    };

                    console.log(finalOrderData)

                    const response = await http.post(`${API_ENDPOINTS.SUBMIT_WESGS_ORDER}`, finalOrderData);

                    setStatusMessage("Order placed successfully. Redirecting...");
                    clearQuizData();
                    setIsLoading(false);
                    setTimeout(() => navigate(`/wesgs/order/success/${response.data.orderId}`), 3000);

                } catch (error) {
                    console.error('Error placing order:', error);
                    setStatusMessage("Error placing order. Redirecting...");
                    setIsLoading(false);
                    setTimeout(() => navigate('/wesgs/summary'), 3000);
                }
            } else {
                console.error('Payment failed or was not completed');
                setStatusMessage("Payment failed. Redirecting...");
                setIsLoading(false);
                setTimeout(() => navigate('/wesgs/summary'), 3000);
            }
        };

        placeOrderWithPaymentId();
    }, [status, paymentId, navigate, quizData, clearQuizData]);

    return (
        <Container fluid className="d-flex align-items-center justify-content-center" style={{ minHeight: '100vh', textAlign: 'center' }}>
            <Row>
                <Col>
                    <div className="text-center">
                        {isLoading && <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />}
                        <Alert color={status === 'paid' ? 'success' : 'danger'} className="mt-4">
                            <h4>{statusMessage}</h4>
                        </Alert>
                        <p>Please wait while we process your request...</p>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default WESGSPaymentCallback;
