import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/ConsultingServices.css"; // Ensure you create this CSS file

// Import icons/images
import carbonIcon from "../assets/images/icons/footprint.png";
import ghgIcon from "../assets/images/icons/ghg.png";
import lifecycleIcon from "../assets/images/icons/lifecycle.png";
import roadmapIcon from "../assets/images/icons/roadmap.png";
import offsetIcon from "../assets/images/icons/carboncredits.png";
import disclosureIcon from "../assets/images/icons/disclosure.png";
import arch from "../assets/images/arch.png";
import cyl from "../assets/images/cyl.png";

const servicesData = [
  {
    icon: carbonIcon,
    title: "Carbon Footprint Analysis",
    description:
      "Measure and analyze your organization’s carbon footprint. Identify key emission sources and develop actionable strategies to reduce CO₂ output.",
  },
  {
    icon: ghgIcon,
    title: "GHG Emissions Inventory & Baseline",
    description:
      "Establish a comprehensive greenhouse gas inventory. Track emissions and set a reliable baseline for sustainability reporting.",
  },
  {
    icon: lifecycleIcon,
    title: "Life Cycle Analysis & Impact Assessment",
    description:
      "Evaluate the environmental impact of your products and services throughout their lifecycle. Implement eco-friendly solutions.",
  },
  {
    icon: roadmapIcon,
    title: "Net Zero Roadmap Development",
    description:
      "Create a strategic roadmap to achieve net-zero emissions. Implement realistic, science-backed strategies to meet sustainability goals.",
  },
  {
    icon: offsetIcon,
    title: "Verified Carbon Offsetting",
    description:
      "Offset your emissions with certified carbon credits. Invest in projects that contribute to climate action and sustainability.",
  },
  {
    icon: disclosureIcon,
    title: "Climate Action Disclosure",
    description:
      "Ensure compliance with global sustainability frameworks. Transparently report carbon reduction efforts and environmental impact.",
  },
];

const ConsultingServices = () => {
  return (
    <section className="consulting-services">
      {/* Background Elements */}
      <img src={arch} alt="Arch" className="bg-image arch" />
      <img src={cyl} alt="Cylinder" className="bg-image cyl" />

      <Container>
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            <h2 className="section-title">Consulting Services</h2>
          </Col>
        </Row>

        <Row className="services-grid">
          {servicesData.map((service, index) => (
            
            <Col lg={4} md={6} sm={12} className="service-box" key={index}>
              <div className="service-card">
                <img src={service.icon} alt={service.title} className="service-icon" />
                <h4 className="service-title">{service.title}</h4>
                <p className="service-description">{service.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ConsultingServices;
