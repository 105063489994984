// src/components/Index.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import routes from "./../Routes/allRoutes";  // Ensure the path to allRoutes is correct
import Layout from "../Layout/Index";  // Adjust the path as needed
import ProtectedRoute from "../Routes/ProtectedRoute";  // Ensure the path to ProtectedRoute is correct
import MainLayout from "../Layout/MainLayout";  // Import the new MainLayout component
import Dashboard from "../pages/profile/Dashboard";
import Offset from "../pages/profile/Offset";
import LoginPage from "../pages/Auth/Login/LoginPage";
import SignupPage from "../pages/Auth/Signup/SignupPage";
import PromoSignupPage from "../pages/Auth/Signup/PromoSignupPage";
import Layout5 from "../pages/landingpage/Layout5";
import Layout5AR from "../pages/landingpage/Layout5AR";
import EC from "../pages/EC/EC";
import WelcomePage from '../pages/quiz/WelcomePage';
import CompanyNamePage from '../pages/quiz/CompanyNamePage';
import VatNumberPage from '../pages/quiz/VatNumberPage';
import CompanyLocationPage from '../pages/quiz/CompanyLocationPage';
import CompanySectorPage from '../pages/quiz/CompanySectorPage';
import SectionOverviewPage from '../pages/quiz/SectionOverviewPage';
import BeforeStartPage from '../pages/quiz/BeforeStartPage';
import TimePeriodPage from '../pages/quiz/TimePeriodPage';
import EmployeeCountPage from '../pages/quiz/EmployeeCountPage';
import ExpensesSectionOverviewPage from "../pages/quiz/ExpensesSectionOverviewPage";
import TotalExpensesPage from "../pages/quiz/TotalExpensesPage";
import ExpensesPage from "../pages/quiz/ExpensesPage";
import VFeulConsumptionPage from "../pages/quiz/VFeulConsumptionPage";
import MFeulConsumptionPage from "../pages/quiz/MFeulConsumptionPage";
import CompanyRevenuePage from "../pages/quiz/CompanyRevenuePage";
import OwnVehiclesPage from '../pages/quiz/OwnVehiclesPage';
import OwnMachineryPage from '../pages/quiz/OwnMachineryPage';
import FacilitySizePage from '../pages/quiz/FacilitySizePage';
import ElectricityConsumptionPage from '../pages/quiz/ElectricityConsumptionPage';
import RenewableElectricityPage from '../pages/quiz/RenewableElectricityPage';
import HeatingReceivedPage from '../pages/quiz/HeatingReceivedPage';
import ElecHeatingConsumptionPage from "../pages/quiz/ElecHeatingConsumptionPage";
import HeatingConsumptionPage from '../pages/quiz/HeatingConsumptionPage';
import CarbonFootprintSummaryPage from '../pages/quiz/CarbonFootprintSummaryPage';
import PurchaseCarbonCredit from "../pages/profile/PurchaseCarbonCredit ";
import ChoosePortfolio from "../pages/profile/ChoosePortfolio";
import MyImpact from "../pages/profile/MyImpact";
import ImpactSharing from "../pages/profile/ImpactSharing";
import ShareYourImpactPublic from "../pages/profile/ShareYourImpactPublic";
import OrdersPage from '../pages/profile/OrdersPage';
import OrderPage from '../pages/profile/OrderPage';
import DevelopersPage from "../pages/profile/DevelopersPage";
import Widgets from "../pages/profile/Widgets";
import PortfolioDetails from '../pages/profile/PortfolioDetails';
import ProjectDetails from "../pages/profile/ProjectDetails";
import LeadsPage from "../pages/profile/admin/LeadsPage";
import LeadDetailPage from "../pages/profile/admin/LeadDetailPage";
import ProjectsPage from "../pages/profile/admin/ProjectsPage";
import ProjectDetailsPage from "../pages/profile/admin/ProjectDetailsPage";
import SettingsPage from "../pages/profile/admin/SettingsPage";
import UsersPage from "../pages/profile/admin/UsersPage";
import UserDetailsPage from "../pages/profile/admin/UserDetailsPage";
import AuditPage from "../pages/profile/admin/AuditPage";
import CheckoutPage from "../pages/profile/CheckoutPage";
import PaymentCallback from "../pages/profile/PaymentCallback";
import SetupPayment from "../pages/profile/SetupPayment";
import PaymentSetupStatus from "../pages/profile/PaymentSetupStatus";
import Terms from "../pages/Auth/Terms/Terms";
import Privacy from "../pages/Auth/Privacy/Privacy";
import TermsAR from "../pages/Auth/Terms/TermsAR";
import PrivacyAR from "../pages/Auth/Privacy/PrivacyAR";
import ShareOrderImpact from "../pages/profile/ShareOrderImpact";
import PublicOrderPage from "../pages/profile/PublicOrderPage";
import Subaccounts from "../pages/profile/Subaccounts";
import AllOrdersPage from "../pages/profile/admin/AllOrdersPage";
import AdminOrderPage from "../pages/profile/admin/AdminOrderPage";
import CompanyPage from "../pages/company/Company";
import CompanyPageAR from "../pages/company/CompanyAR";
import PricingPage from "../pages/pricing/PricingPage";
import PricingPageAR from "../pages/pricing/PricingPageAR";
import OrderConfirmation from "../pages/profile/OrderConfirmation";
import BillingPage from "../pages/profile/BillingPage";
import AccountPage from "../pages/profile/AccountPage";
import PaymentUpdateStatus from "../pages/profile/PaymentUpdateStatus";

// WESGS
import WelcomePageWESGS from "../pages/wesgs/WelcomePage";
import AttendeeContact from "../pages/wesgs/AttendeeContact";
import DaysAttending from "../pages/wesgs/DaysAttending";
import TravelingInfo from "../pages/wesgs/TravelingInfo";
import CommuteInfo from "../pages/wesgs/CommuteInfo";
import SummaryPage from "../pages/wesgs/SummaryPage";
import WESGSPaymentCallback from "../pages/wesgs/WESGSPaymentCallback";
import OrderConfirmationWESGS from "../pages/wesgs/OrderConfirmationWESGS";

const Index = () => {
  return (
    <Routes>
      <Route path="/profile" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}>
        <Route path="/profile/home" element={<Offset />} />
        <Route path="/profile/offset" element={<PurchaseCarbonCredit />} />
        <Route path="/profile/purchase" element={<PurchaseCarbonCredit />} />
        <Route path="/profile/choose-portfolio" element={<ChoosePortfolio />} />
        <Route path="/profile/portfolio/:portfolioId" element={<PortfolioDetails />} />
        {/* <Route path="/profile/my-impact" element={<MyImpact />} />
                <Route path="/profile/impact-sharing" element={<ImpactSharing />} /> */}
        <Route path="/profile/orders" element={<OrdersPage />} />
        <Route path="/profile/order/:orderId" element={<OrderPage />} />
        <Route path="/profile/order/success/:orderId" element={<OrderConfirmation />} />
        <Route path="/profile/developer" element={<DevelopersPage />} />
        <Route path="/profile/widgets" element={<Widgets />} />
        <Route path="/profile/subaccounts" element={<Subaccounts />} />
        <Route path="/profile/checkout" element={<CheckoutPage />} />
        <Route path="/profile/payment-callback" element={<PaymentCallback />} />
        <Route path="/profile/setup-payment" element={<SetupPayment />} />
        <Route path="/profile/setup-payment-status" element={<PaymentSetupStatus />} />
        <Route path="/profile/update-payment-status/:companyId" element={<PaymentUpdateStatus />} />
        <Route path="/profile/billing" element={<BillingPage />} />
        <Route path="/profile/account" element={<AccountPage />} />
        <Route path="/profile/admin/leads" element={<LeadsPage />} />
        <Route path="/profile/admin/leads/:leadId" element={<LeadDetailPage />} />
        <Route path="/profile/admin/projects" element={<ProjectsPage />} />
        <Route path="/profile/admin/projects/:projectId" element={<ProjectDetailsPage />} />
        <Route path="/profile/project/:projectId" element={<ProjectDetails />} />
        <Route path='/profile/admin/settings' element={<SettingsPage />} />
        <Route path="/profile/admin/users" element={<UsersPage />} />
        <Route path="/profile/admin/users/:userId" element={<UserDetailsPage />} />
        <Route path="/profile/admin/logs" element={<AuditPage />} />
        <Route path="/profile/admin/orders" element={<AllOrdersPage />} />
        <Route path="/profile/admin/order/:orderId" element={<AdminOrderPage />} />
        {/* Add more routes here */}
      </Route>

      <Route path="/quiz" element={<ProtectedRoute></ProtectedRoute>}>
        <Route path="/quiz/welcome" element={<WelcomePage />} />
        <Route path="/quiz/ec" element={<EC />} />
        <Route path="/quiz/company-name" element={<CompanyNamePage />} />
        <Route path="/quiz/vat-number" element={<VatNumberPage />} />
        <Route path="/quiz/company-location" element={<CompanyLocationPage />} />
        <Route path="/quiz/company-sector" element={<CompanySectorPage />} />
        <Route path="/quiz/before-start" element={<BeforeStartPage />} />
        <Route path="/quiz/time-period" element={<TimePeriodPage />} />
        <Route path="/quiz/employee-count" element={<EmployeeCountPage />} />
        <Route path="/quiz/revenue" element={<CompanyRevenuePage />} />
        <Route path="/quiz/own-vehicles" element={<OwnVehiclesPage />} />
        <Route path="/quiz/vfuel-consumption" element={<VFeulConsumptionPage />} />
        <Route path="/quiz/mfuel-consumption" element={<MFeulConsumptionPage />} />
        <Route path="/quiz/own-machinery" element={<OwnMachineryPage />} />
        <Route path="/quiz/facility-size" element={<FacilitySizePage />} />
        <Route path="/quiz/electricity-consumption" element={<ElectricityConsumptionPage />} />
        <Route path="/quiz/renewable-electricity" element={<RenewableElectricityPage />} />
        <Route path="/quiz/heating-received" element={<HeatingReceivedPage />} />
        <Route path="/quiz/elec-heating-consumption" element={<ElecHeatingConsumptionPage />} />
        <Route path="/quiz/heating-consumption" element={<HeatingConsumptionPage />} />
        <Route path="/quiz/carbon-footprint-summary" element={<CarbonFootprintSummaryPage />} />
        <Route path="/quiz/total-expenses" element={<ExpensesSectionOverviewPage />} />
        <Route path="/quiz/total-expenses-details" element={<TotalExpensesPage />} />
        <Route path="/quiz/expenses" element={<ExpensesPage />} />
        <Route path="/quiz/section-overview" element={<SectionOverviewPage />} />
      </Route>
      <Route path="/wesgs">
        <Route path="/wesgs/welcome" element={<WelcomePageWESGS />} />
        <Route path="/wesgs/attendee-contact" element={<AttendeeContact />} />
        <Route path="/wesgs/days-attending" element={<DaysAttending />} />
        <Route path="/wesgs/travel-info" element={<TravelingInfo />} />
        <Route path="/wesgs/commute-info" element={<CommuteInfo />} />
        <Route path="/wesgs/summary" element={<SummaryPage />} />
        <Route path="/wesgs/payment-callback" element={<WESGSPaymentCallback />} />
        <Route path="/wesgs/order/success/:orderId" element={<OrderConfirmationWESGS />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/terms" element={<Terms />} />
      <Route path="/ar/privacy" element={<PrivacyAR />} />
      <Route path="/ar/terms" element={<TermsAR />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/promo-signup" element={<PromoSignupPage />} />
      <Route path="/" element={<Layout5 />} />
      <Route path="/ar" element={<Layout5AR />} />
      <Route path='/company' element={<CompanyPage />} />
      <Route path='/ar/company' element={<CompanyPageAR />} />
      <Route path='/pricing' element={<PricingPage />} />
      <Route path='/ar/pricing' element={<PricingPageAR />} />
      {/* <Route path="/share-impact/:id" element={<ShareYourImpactPublic />} />
            <Route path="/share-order-impact/:id" element={<ShareOrderImpact />} /> */}
      <Route path="/order-detail/:orderId" element={<PublicOrderPage />} />
      {/* Add other routes without sidebar here */}
    </Routes>
  );
};

export default Index;
