import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "../assets/css/FooterSection.css"; // Ensure to create this CSS file

// Logo Import
import logolight from "../assets/images/nz-logo-w.png";

// Social Media Icons
const socialLinks = [
  { iconClass: "mdi mdi-instagram", url: "#" },
  { iconClass: "mdi mdi-facebook", url: "#" },
  { iconClass: "mdi mdi-twitter", url: "#" },
  { iconClass: "mdi mdi-linkedin", url: "https://www.linkedin.com/company/c-mass" },
];

// Footer Links Data
const footerLinks = [
  {
    title: "Quick Links",
    links: [
      { name: "Home", href: "/" },
      { name: "API Documentation", href: "https://c-mass.mintlify.app/introduction" },
      { name: "Prices", href: "/pricing" },
      { name: "Company", href: "/company" },
    ],
  },
  {
    title: "Help",
    links: [
      { name: "Help Center", href: "/help" },
      { name: "Trust and Safety", href: "/trust" },
      { name: "Privacy Settings", href: "/privacy-settings" },
    ],
  },
];

const Footer = () => {
  return (
    <footer className="footer-section">
      <Container>
        <Row>
          {/* Left Section - Logo and Social Links */}
          <Col lg={3} className="footer-left">
            <Link to="/">
              <img src={logolight} alt="c-mass" className="footer-logo" />
            </Link>
            <p className="footer-text">
              Think Globally, Act Locally
            </p>
            <div className="footer-social-links">
              {socialLinks.map((item, index) => (
                <a key={index} href={item.url} className="social-icon">
                  <i className={item.iconClass}></i>
                </a>
              ))}
            </div>
          </Col>

          {/* Middle Sections - Links */}
          {footerLinks.map((section, index) => (
            <Col lg={3} md={6} key={index}>
              <div className="footer-links">
                <h6 className="footer-title">{section.title}</h6>
                <ul className="footer-list">
                  {section.links.map((link, idx) => (
                    <li key={idx}>
                      <Link to={link.href}>{link.name}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          ))}

          {/* Right Section - Registration and VAT Numbers */}
          <Col lg={3} md={6}>
            <div className="footer-info">
              <h6 className="footer-title">Commercial Registration Number</h6>
              <p className="footer-text">1009118168</p>
              <h6 className="footer-title">VAT Number</h6>
              <p className="footer-text">3125984109</p>
            </div>
          </Col>
        </Row>

        {/* Bottom Copyright and Policies */}
        <Row className="footer-bottom">
          {/* <Col md={6}>
            <p>© 2025 All rights reserved!</p>
          </Col> */}
          <Col md={6} className="text-end">
            <Link to="/privacy" className="footer-link">Privacy Policy</Link>
            <Link to="/terms" className="footer-link">Terms and Conditions</Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
