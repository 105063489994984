import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem, Collapse } from 'reactstrap';
import { ModeContext } from '../contexts/mode/ModeContext.js'; // Import the context
import logo from '../assets/images/nz-logo.png'; // Adjust the path as necessary
import logoWhite from '../assets/images/nz-logo-w.png';
import UserContext from "../contexts/user/UserContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import '../assets/css/sidebar.css'; // Ensure you're importing the CSS correctly
import { USER_TYPE } from '../utils/constants/users.js';

const Sidebar = ({ company }) => {
    const { user } = useContext(UserContext);
    const [isCollapsed, setIsCollapsed] = useState(window.innerWidth <= 768);
    const [impactOpen, setImpactOpen] = useState(false); // State for Impact collapse
    const [developersOpen, setDevelopersOpen] = useState(false); // State for Developers collapse
    const [adminOpen, setAdminOpen] = useState(false); // State for Admin collapse
    const { isTestMode, setisTestMode } = useContext(ModeContext); // Use the context

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setIsCollapsed(true);
            } else {
                setIsCollapsed(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleModeToggle = () => {
        setisTestMode(!isTestMode);
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const toggleImpact = () => setImpactOpen(!impactOpen);
    const toggleDevelopers = () => setDevelopersOpen(!developersOpen);
    const toggleAdmin = () => setAdminOpen(!adminOpen); // Toggle for Admin

    return (
        <>
            <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
                <div className="sidebar-header">
                    <a href="/profile/home"><img src={logoWhite} alt="Logo" className="logo" /></a>
                    <h3>{company.name}</h3>
                    <p><i className="bi bi-pin-map-fill"></i> {company.location}</p>
                </div>
                <div className="sandbox-toggle">
                    <button
                        className={`test-mode-btn ${isTestMode ? 'active' : ''}`}
                        onClick={handleModeToggle}
                    >
                        <span className="label-text">{isTestMode ? 'Testing Mode' : 'Production Mode'}</span>
                        <span className="label-text-mobile">{isTestMode ? 'TM' : 'PM'}</span>
                    </button>
                </div>

                <hr className="divider" />
                <Nav vertical>
                    {/* Admin Section - Only for CM_ADMIN users */}
                    {user?.accountType === USER_TYPE.ADMIN && (
                        <>
                            <NavItem onClick={toggleAdmin} style={{ cursor: 'pointer' }}>
                                <div className="nav-link">
                                    <i className="bi bi-person-badge"></i> <span className="label-text">Admin</span>
                                    <FontAwesomeIcon icon={adminOpen ? faChevronDown : faChevronRight} className="arrow" />
                                </div>
                            </NavItem>
                            <Collapse isOpen={adminOpen}>
                                <NavItem>
                                    <NavLink to="/profile/admin/leads" className="nav-link sub-link">
                                        <i className="bi bi-people"></i> <span className="label-text">Leads</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/profile/admin/projects" className="nav-link sub-link">
                                        <i className="bi bi-bar-chart"></i> <span className="label-text">Projects</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/profile/admin/users" className="nav-link sub-link">
                                        <i className="bi bi-person-circle"></i> <span className="label-text">Users</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/profile/admin/orders" className="nav-link sub-link">
                                        <i class="bi bi-receipt-cutoff"></i> <span className="label-text">Orders</span>
                                    </NavLink>
                                </NavItem>
                                {/* <NavItem>
                                    <NavLink to="/profile/admin/invoices" className="nav-link sub-link">
                                        <i className="bi bi-receipt-cutoff"></i> <span className="label-text">Invoices</span>
                                    </NavLink>
                                </NavItem> */}
                                <NavItem>
                                    <NavLink to="/profile/admin/settings" className="nav-link sub-link">
                                        <i className="bi bi-gear"></i> <span className="label-text">Settings</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink to="/profile/admin/logs" className="nav-link sub-link">
                                        <i className="bi bi-list-columns-reverse"></i> <span className="label-text">Logs</span>
                                    </NavLink>
                                </NavItem>
                            </Collapse>
                        </>
                    )}

                    {/* Dashboard */}
                    <NavItem>
                        <NavLink to="/profile/home" className="nav-link">
                            <i className="bi bi-house"></i> <span className="label-text">Home</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/profile/developer" className="nav-link">
                            <i className="bi bi-code-slash"></i> <span className="label-text">Devs</span>
                        </NavLink>
                    </NavItem>
                    {/* Dashboard */}
                    <NavItem>
                        <NavLink to="/profile/purchase" className="nav-link">
                            <i className="bi bi-coin"></i> <span className="label-text">Purchase Credits</span>
                        </NavLink>
                    </NavItem>

                    {/* Portfolio */}
                    <NavItem>
                        <NavLink to="/profile/choose-portfolio" className="nav-link">
                            <i className="bi bi-briefcase"></i> <span className="label-text">Portfolios</span>
                        </NavLink>
                    </NavItem>

                    {/* Orders */}
                    <NavItem>
                        <NavLink to="/profile/orders" className="nav-link">
                            <i className="bi bi-list-task"></i> <span className="label-text">Orders</span>
                        </NavLink>
                    </NavItem>

                    {/* <NavItem>
                        <NavLink to="/profile/impact-sharing" className="nav-link">
                            <i className="bi bi-megaphone"></i> <span className="label-text">Sharing</span>
                        </NavLink>
                    </NavItem> */}

                    {/* Impact Section */}
                    {/* <NavItem onClick={toggleImpact} style={{ cursor: 'pointer' }}>
                        <div className="nav-link">
                            <i className="bi bi-globe-americas"></i> <span className="label-text">Impact</span>
                            <FontAwesomeIcon icon={impactOpen ? faChevronDown : faChevronRight} className="arrow" />
                        </div>
                    </NavItem>
                    <Collapse isOpen={impactOpen}>
                        <NavItem>
                            <NavLink to="/profile/my-impact" className="nav-link sub-link">
                                <i className="bi bi-tree"></i> <span className="label-text">My Impact</span>
                            </NavLink>
                        </NavItem>
                        
                    </Collapse> */}

                    {/* Developers Section */}
                    {/* <NavItem onClick={toggleDevelopers} style={{ cursor: 'pointer' }}>
                        <div className="nav-link">
                            <i className="bi bi-code-slash"></i> <span className="label-text">Developers</span>
                            <FontAwesomeIcon icon={developersOpen ? faChevronDown : faChevronRight} className="arrow" />
                        </div>
                    </NavItem>
                    <Collapse isOpen={developersOpen}>
                        <NavItem>
                            <NavLink to="/profile/developer" className="nav-link sub-link">
                                <i className="bi bi-file-code"></i> <span className="label-text">APIs</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/profile/widgets" className="nav-link sub-link">
                                <i className="bi bi-window-desktop"></i> <span className="label-text">Widgets</span>
                            </NavLink>
                        </NavItem>
                    </Collapse> */}
                    {user?.accountType === USER_TYPE.PREMIUM && (
                        <>
                            <NavItem style={{ cursor: 'pointer' }}>
                                <NavLink to="/profile/subaccounts" className="nav-link">
                                    <i className="bi bi-person-badge"></i> <span className="label-text">Subaccounts</span>
                                </NavLink>
                            </NavItem>
                        </>
                    )}
                    {/* Testing Mode Toggle */}

                </Nav>

            </div>
            <button className="sidebar-toggle" onClick={toggleSidebar}>
                {isCollapsed ? 'Expand' : 'Collapse'}
            </button>
        </>
    );
};

export default Sidebar;
