import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpageWESGS from "../../Layout/NavbarSubpageWESGS";
import { Col, Container, Row, Button, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const AttendeeContact = () => {
    const { updateQuizData } = useContext(QuizContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(''); // New state for email error
    const [totalQuestions] = useState(4);
    const currentQuestion = 1;

    const navigate = useNavigate();

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation regex
        return regex.test(email);
    };

    const handleNextClick = () => {
        if (name && email && validateEmail(email)) {
            updateQuizData({ name, email, totalQuestions });
            navigate('/wesgs/travel-info');
        } else {
            setEmailError('Please enter a valid email address.');
        }
    };

    const handleNameInputChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailInputChange = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Live validation
        if (value === '' || validateEmail(value)) {
            setEmailError('');
        } else {
            setEmailError('Invalid email format.');
        }
    };

    return (
        <React.Fragment>
            <NavbarSubpageWESGS />
            <section className="bg-home5" id="home">
                <div className="bg-overlay"></div>
                <Container>
                    <div className="position-relative" style={{ zIndex: "1" }}>
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <div className="progress-wrapper sticky-top mb-3">
                                    <div className="d-flex justify-content-between mb-1">
                                        <span>{currentQuestion} / {totalQuestions}</span>
                                    </div>
                                    <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
                                </div>
                                <h2 className="mb-4">Tell us about yourself</h2>
                                <Row>
                                    <Col lg={6} className="mb-3">
                                        <div className="mt-4">
                                            <h4>Your Name:</h4>
                                            <input
                                                type="text"
                                                value={name}
                                                onChange={handleNameInputChange}
                                                className="form-control"
                                                placeholder="Your Name"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} className="mb-3">
                                        <div className="mt-4">
                                            <h4>Your Email:</h4>
                                            <input
                                                type="email"
                                                value={email}
                                                onChange={handleEmailInputChange}
                                                className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                                placeholder="Your Email"
                                            />
                                            {emailError && <div className="invalid-feedback">{emailError}</div>}
                                        </div>
                                    </Col>
                                </Row>
                                <Button
                                    onClick={handleNextClick}
                                    disabled={!name || !email || !!emailError}
                                    className="btn btn-primary btn-block"
                                >
                                    Next
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col lg={6}>
                            <div className="me-4">
                                <h6 className="sub-title mb-1"><i className="bi bi-question-circle"></i> Why are we asking this?</h6>
                                <p className="text-muted fs-17">
                                    We need your name to personalize your voluntary carbon action and your email to send you the receipt and certificate for your contribution.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default AttendeeContact;