import React, { useState } from "react";
import { Col, Container, Row, Form, Input, FormGroup, Button } from "reactstrap";
import { API_ENDPOINTS } from "../frameworks/basic-rest/api-endpoints";
import http from "../frameworks/basic-rest/http";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../assets/css/ContactSection.css"; // تأكد من إنشاء ملف التنسيق CSS

// استيراد الكائن ثلاثي الأبعاد
import object3D from "../assets/images/cross.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    comments: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await http.post(API_ENDPOINTS.INQUIRY, formData);
      toast.success("تم إرسال البريد الإلكتروني بنجاح!");

      setFormData({
        name: "",
        email: "",
        number: "",
        comments: "",
      });
    } catch (error) {
      toast.error("فشل في إرسال البريد الإلكتروني. يرجى المحاولة مرة أخرى.");
    }
  };

  return (
    <section className="contact-section" style={{ direction: "rtl" }} id="contact">
      <Container>
        <Row className="align-items-center">
          {/* نموذج الاتصال */}
          <Col lg={6}>
            <h2 className="contact-title">تواصل معنا</h2>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Input
                  name="name"
                  type="text"
                  className="contact-input"
                  placeholder="الاسم"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="email"
                  name="email"
                  className="contact-input"
                  placeholder="البريد الإلكتروني"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  name="number"
                  className="contact-input"
                  placeholder="رقم الهاتف"
                  value={formData.number}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="textarea"
                  name="comments"
                  className="contact-input message-box"
                  placeholder="الرسالة"
                  value={formData.comments}
                  onChange={handleChange}
                />
              </FormGroup>
              <Button type="submit" className="contact-submit-btn" style={{ borderRadius: '20px', padding: '6px 14px', marginRight: '5px' }}>
                أرسل الآن
              </Button>
            </Form>
          </Col>

          {/* كائن ثلاثي الأبعاد في الجانب الأيمن */}
          <Col lg={6} className="d-flex justify-content-center">
            <img src={object3D} alt="كائن ثلاثي الأبعاد" className="contact-object" style={{ width: '400px' }} />
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </section>
  );
};

export default Contact;
