import React, { createContext, useState, useEffect } from 'react';

// Create the context
export const ModeContext = createContext();

// Create the provider component
export const ModeProvider = ({ children }) => {
  const [isTestMode, setisTestMode] = useState(false);

  // On initial load, check localStorage
  useEffect(() => {
    const savedMode = localStorage.getItem('sandboxMode');
    if (savedMode) {
      setisTestMode(savedMode === 'true');
    }
  }, []);

  // Update localStorage whenever the mode changes
  useEffect(() => {
    localStorage.setItem('sandboxMode', isTestMode);
  }, [isTestMode]);

  return (
    <ModeContext.Provider value={{ isTestMode, setisTestMode }}>
      {children}
    </ModeContext.Provider>
  );
};
