// WESGSPaymentComponent.js
import React, { useEffect } from 'react';

const WESGSPaymentComponent = ({ price, name, email, kg_amount }) => {
    useEffect(() => {
        if (window.Moyasar) {
            window.Moyasar.init({
                element: '.mysr-form',
                amount: price * 100,
                currency: 'SAR',
                description: `${name} ${email} Wolrd ESG Summit Travel & Commute`,
                publishable_api_key: process.env.REACT_APP_MOYASAR_API_KEY,
                callback_url: `${window.location.origin}/wesgs/payment-callback?price=${price}&kg_amount=${kg_amount}&companyId=${process.env.REACT_APP_WESGS_ID}&name=${name}&email=${email}`,
                methods: ['creditcard', 'mada'],
                on_completed: (payment) => {
                    //updateQuizData(price, kg_amount)
                }
            });
        }
    }, [price]);

    return <div className="mysr-form"></div>;
};

export default WESGSPaymentComponent;
