import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../assets/css/ProductsSection.css"; // Ensure you create this CSS file

// Import images
import airlines from "../assets/images/icons/tickets.png";
import ecommerce from "../assets/images/icons/order.png";
import logistics from "../assets/images/icons/shipping.png";
import hospitality from "../assets/images/icons/hotel.png";
import softwareDataCenters from "../assets/images/icons/snd.png";
import events from "../assets/images/icons/event.png";

import arch from "../assets/images/arch.png";
import cyl from "../assets/images/cyl.png";

const productsData = [
  {
    image: airlines,
    title: "Carbon-Neutral Flights",
    description:
      "Enable airlines to offer carbon-neutral ticketing by integrating emissions offsetting into their booking systems, providing travelers with eco-conscious choices.",
  },
  {
    image: hospitality,
    title: "Carbon-Neutral Hospitality",
    description:
      "Help hotels, resorts, and lodging services achieve carbon neutrality by offsetting energy consumption and operational emissions, offering guests a sustainable stay.",
  },
  {
    image: logistics,
    title: "Green Logistics & Freight",
    description:
      "Support sustainable supply chains by offsetting emissions from logistics, freight, and transportation services, reducing environmental impact.",
  },
  {
    image: softwareDataCenters,
    title: "Sustainable Software & Data Centers",
    description:
      "Power software and digital infrastructure with green energy. Offset emissions from data centers and cloud services to build a sustainable, low-carbon digital ecosystem.",
  },
  {
    image: ecommerce,
    title: "Sustainable E-Commerce",
    description:
      "Enable climate-conscious shopping by offsetting emissions from product manufacturing and shipping, providing customers with sustainable purchasing options.",
  },
  {
    image: events,
    title: "Carbon-Neutral Events",
    description:
      "Make events, conferences, and expos carbon-neutral by offsetting emissions from travel, energy use, and logistics, ensuring a sustainable experience for attendees.",
  },
];



const ProductsSection = () => {
  return (
    <section className="products-section">
      {/* Background Elements */}
      <img src={arch} alt="Arch" className="bg-image arch" />
      <img src={cyl} alt="Cylinder" className="bg-image cyl" />

      <Container>
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            <h2 className="section-title">Integrate Carbon-Neutral Solutions into Your Business</h2>
            <p className="section-description">
              Leverage our API to provide carbon-neutral services across industries, allowing businesses to 
              offset emissions seamlessly while offering sustainable choices to customers.
            </p>
          </Col>
        </Row>

        <Row className="products-grid">
          {productsData.map((product, index) => (
            <Col lg={4} md={6} sm={12} className="product-box" key={index}>
              <div className="product-card">
                <img src={product.image} alt={product.title} className="product-image" />
                <h4 className="product-title">{product.title}</h4>
                <p className="product-description">{product.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ProductsSection;
