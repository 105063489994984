import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { ModeProvider } from './contexts/mode/ModeContext';
import { UserProvider } from './contexts/user/UserContext';
import { OrderProvider } from './contexts/order/OrderContext';
import { QuizProvider } from './contexts/quiz/QuizContext';
import { GoogleOAuthProvider } from "@react-oauth/google"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
    <GoogleOAuthProvider clientId='780694807356-aceutc0dbefiacvp1uu5ik28eoljs6bi.apps.googleusercontent.com'>
      <UserProvider>
        <ModeProvider>
          <OrderProvider>
            <QuizProvider>
            <App />
            </QuizProvider>
          </OrderProvider>
        </ModeProvider>
      </UserProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
