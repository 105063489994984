import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import "../assets/css/PricingSection.css"; // تأكد من تنسيق القسم بشكل صحيح
import circle from "../assets/images/circle-rev.png";

const PricingData = [
  {
    title: "الباقة القياسية",
    price: "80 ريال",
    subtitle: "/طن",
    gradient: true, // يستخدم لتطبيق التدرج اللوني
    features: [
      "لوحة تحكم وطلبات عبر الويب",
      "محفظة تعويض متنوعة",
      "دعم تقني",
      "التكاملات",
      "دعم أساسي"
    ],
    buttonLabel: "ابدأ مجانًا",
    buttonLink: "/signup",
  },
  {
    title: "الباقة المخصصة",
    price: "حسب الطلب",
    subtitle: "/",
    gradient: false,
    features: [
      "تقديرات مخصصة",
      "نقاط نهاية API",
      "محفظة مخصصة",
      "دعم على مدار الساعة",
      "دعم أساسي"
    ],
    buttonLabel: "تواصل معنا",
    buttonLink: "/contact",
  },
];

const Pricing = () => {
  return (
    <section className="pricing-section" id="pricing" style={{ direction: "rtl" }}> 
      <Container>
        <Row className="justify-content-center">
          <Col lg={7} className="text-center">
            <h3>أسعارنا المرنة حسب الاستهلاك</h3>
          </Col>
        </Row>

        <Row className="pricing-cards-container">
          {PricingData.map((plan, index) => (
            <Col key={index} lg={6} md={8}>
              <div className={`pricing-box ${plan.gradient ? "gradient-background" : ""}`}>
                <p className="plan-title">{plan.title}</p>
                <h1 className="plan-price">
                  {plan.price} <span className="plan-subtitle">{plan.subtitle}</span>
                </h1>
                <Link to={plan.buttonLink} className="pricing-button" style={{ borderRadius: '20px', padding: '6px 14px', marginRight: '5px' }}>
                  {plan.buttonLabel}
                </Link>
                <ul className="features-list">
                  {plan.features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
              </div>
            </Col>
          ))}
        </Row>
        <img src={circle} alt="دائرة" className="bg-image circle-pricing" style={{ width: '100px', right: '1px' }} />
      </Container>
    </section>
  );
};

export default Pricing;
