import React, { useEffect, useState, useContext } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { useParams, Link } from 'react-router-dom';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/ProjectDetails.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faIdCard, faTablet } from '@fortawesome/free-solid-svg-icons';
import Spinner from "react-bootstrap/Spinner";
import { ModeContext } from '../../contexts/mode/ModeContext';

const ProjectDetails = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const { isTestMode } = useContext(ModeContext);

  useEffect(() => {
    const fetchProjectDetails = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.PROJECT}/${projectId}?isTest=${isTestMode}`);
        setProject(response.data);
      } catch (error) {
        console.error("Error fetching project details:", error);
      }
    };
    fetchProjectDetails();
  }, [projectId, isTestMode]);

  if (!project) {
    return <Container className="dashboard"><Spinner /></Container>;
  }

  return (
    <Container className="dashboard">
      {/* Back to Portfolio */}
      <Row>
        <Col>
          <Link to={`/profile/portfolio/${project.portfolio._id}`} className="back-link">
            <span style={{ color: 'black' }}>
              <FontAwesomeIcon icon={faArrowLeftLong} /> Back to Portfolio
            </span>
          </Link>
        </Col>
      </Row>

      {/* Project Header */}
      <Row className="mt-4 align-items-center">
  <Col md="12">
    <div className="project-header" style={{ backgroundImage: `url(${project.image || '/default-image.jpg'})`, borderRadius: '20px' }}>
      <div className="project-header-overlay">
        <h1 className="project-title wf">{project.name}</h1>
        <p className="wf"><FontAwesomeIcon icon={faIdCard} /> <strong>Developer:</strong> {project.developer}</p>
        <p className="wf"><FontAwesomeIcon icon={faTablet} /> <strong>Type:</strong> {project.type || 'N/A'}</p>
      </div>
    </div>
  </Col>
</Row>


      {/* SDGs & Registry/Ratings Section */}
      <Row className="mt-4">
        <Col md="4">
          <Card className="sdg-card" style={{borderRadius: '20px'}}>
            <CardBody>
              <h5>KSA Sustainable Development Goals</h5>
              <div className="sdg-section">
                {/* SDG Images */}
                <div className="sdg-images">
                  {project.sustainableDevelopment.length > 0 ? (
                    project.sustainableDevelopment.map((goal, index) => (
                      <img
                        key={index}
                        src={goal}
                        alt={`SDG ${index + 1}`}
                        className="goal-image"
                      />
                    ))
                  ) : (
                    <p>No SDGs listed.</p>
                  )}
                </div>

               
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="sdg-card" style={{borderRadius: '20px'}}>
            <CardBody>
         {/* Carbon Registry & Ratings */}
         <div className="registry-ratings">
                  <div className="info-box" style={{borderRadius: '20px'}}>
                    <h6>Carbon Registry</h6>
                    <p>{project.carbonRegistry ? `${project.carbonRegistry.name}: ${project.carbonRegistry.id}` : 'N/A'}</p>
                  </div>
                  <div className="info-box">
                    <h6>Third-Party Ratings</h6>
                    {project.thirdPartyRatings.length > 0 ? (
                      project.thirdPartyRatings.map((rating, index) => (
                        <p key={index}>{rating.company}: {rating.rating}</p>
                      ))
                    ) : (
                      <p>No third-party ratings.</p>
                    )}
                  </div>
                </div>
            </CardBody>
          </Card>
        </Col>

      </Row>



      {/* Project Description */}
      <Row className="mt-4">
        <Col md="12">
          <Card className="description-card" style={{borderRadius: '20px'}}>
            <CardBody>
              <h5>Project Description</h5>
              <p>{project.description || 'No description provided.'}</p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectDetails;
