import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Table, Button, Card, CardBody } from 'reactstrap';
import http from "../../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../../frameworks/basic-rest/api-endpoints";
import Spinner from "react-bootstrap/Spinner";
import UserContext from "../../../contexts/user/UserContext";
import { ModeContext } from '../../../contexts/mode/ModeContext';
import { useNavigate, useOutletContext } from 'react-router-dom';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const AllOrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reportData, setReportData] = useState(null);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const { isTestMode } = useContext(ModeContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ordersResponse = await http.get(`${API_ENDPOINTS.ALL_ORDERS}?isTest=${isTestMode}`);
        setOrders(ordersResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setOrders([]);
      } finally {
        setLoading(false);
      }
    };
    const fetchReportData = async () => {
      try {
        const response = await http.get(API_ENDPOINTS.AUDIT_REPORT);
        setReportData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setReportData([]);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    fetchReportData();
  }, [user, isTestMode]);

  const getStatusBadgeClass = (status) => {
    switch (status.toLowerCase()) {
      case 'placed':
        return 'bg-warning text-dark'; // Yellow badge for placed
      case 'canceled':
        return 'bg-danger'; // Red badge for canceled
      case 'fulfilled':
        return 'bg-success'; // Green badge for fulfilled
      default:
        return 'bg-secondary'; // Default badge if status is unknown
    }
  };

  const getViaBadgeClass = (via) => {
    switch (via?.toLowerCase()) {
      case 'platform':
        return 'bg-warning text-dark'; // Yellow badge for placed
      case 'api':
        return 'bg-danger'; // Red badge for canceled
      default:
        return 'bg-secondary'; // Default badge if status is unknown
    }
  };

  const getPaymentStatusBadgeClass = (ps) => {
    switch (ps?.toLowerCase()) {
      case 'paid':
        return 'bg-success'; // Yellow badge for placed
      default:
        return 'bg-danger'; // Default badge if status is unknown
    }
  };

  const handleOrderClick = (orderId) => {
    navigate(`/profile/admin/order/${orderId}`);
  };

  const generateCarbonCreditReportPDF = () => {
      const pdf = new jsPDF("p", "pt", "a4");
      const pageHeight = pdf.internal.pageSize.getHeight();
      const pageWidth = pdf.internal.pageSize.getWidth();
      let y = 40;
  
      const addPageIfNeeded = (additionalHeight = 20) => {
          if (y + additionalHeight > pageHeight - 40) {  // Leave some margin
              pdf.addPage();
              y = 40;  // Reset y for the new page
          }
      };
  
      // Header Section
      pdf.setFont('Helvetica', 'bold');
      pdf.setFontSize(24);
      pdf.text("Carbon Credit Accounting Report", pageWidth / 2, y, { align: "center" });
      y += 30;
  
      pdf.setFontSize(16);
      pdf.text(`Total Credits in Inventory: ${reportData.totalCredits} kg`, 50, y);
      y += 20;
      pdf.text(`Total Allocated Credits: ${reportData.allocatedCredits} kg`, 50, y);
      y += 30;
  
      // Project Breakdown
      pdf.setFontSize(18);
      pdf.text("Project-Level Breakdown", 50, y);
      y += 20;
  
      reportData.projects.forEach(project => {
          addPageIfNeeded(50);
  
          pdf.setFontSize(14);
          pdf.text(`Project: ${project.name}`, 50, y);
          y += 15;
  
          pdf.setFontSize(12);
          pdf.text(`Total Credits: ${project.totalCredits} kg`, 60, y);
          y += 15;
  
          project.batches.forEach(batch => {
              addPageIfNeeded(20);
              pdf.text(`Batch: ${batch.batchNumber}, Credits: ${batch.totalCredits} kg, Allocated: ${batch.allocatedCredits} kg`, 70, y);
              y += 15;
          });
  
          y += 10;
      });
  
      // Company Breakdown
      pdf.addPage();
      y = 40;
      pdf.setFontSize(18);
      pdf.text("Company-Level Summary", 50, y);
      y += 20;
  
      reportData.companies.forEach(company => {
          addPageIfNeeded(50);
  
          pdf.setFontSize(14);
          pdf.text(`Company: ${company.name}`, 50, y);
          y += 15;
  
          pdf.setFontSize(12);
          pdf.text(`Total Credits Purchased: ${company.totalCreditsPurchased} kg`, 60, y);
          y += 15;
  
          company.projects.forEach(project => {
              addPageIfNeeded(20);
              pdf.text(`- ${project.name}: ${project.creditsAllocated} kg`, 70, y);
              y += 15;
          });
  
          y += 10;
      });
  
      // Orders Summary
      pdf.addPage();
      y = 40;
      pdf.setFontSize(18);
      pdf.text("Orders Summary", 50, y);
      y += 20;
  
      reportData.orders.forEach(order => {
          addPageIfNeeded(50);
  
          pdf.setFontSize(14);
          pdf.text(`Order ID: ${order.orderNumber}`, 50, y);
          y += 15;
  
          pdf.setFontSize(12);
          pdf.text(`Date: ${new Date(order.date).toLocaleDateString()}, Credits: ${order.credits} kg`, 60, y);
          y += 15;
  
          order.projects.forEach(project => {
              addPageIfNeeded(20);
              pdf.text(`Project: ${project.name}, Batch: ${project.batchNumber}, Credits: ${project.creditsAllocated} kg`, 70, y);
              y += 15;
          });
  
          y += 10;
      });
  
      pdf.save("Carbon_Credit_Accounting_Report.pdf");
  };

  return (
    <Container className="dashboard">
      <h2 className="mb-4" style={{ fontWeight: 'bold' }}>Order History</h2>
    <button className='primary' onClick={generateCarbonCreditReportPDF}>Report</button>
      <Row>
        <Col>
          <Table className="subaccounts-table" responsive>
            <thead>
              <tr>
                <th>ORDER ID</th>
                <th>COMPANY</th>
                <th>STATUS</th>
                <th>AMOUNT</th>
                <th>DESCRIPTION</th>
                <th>PLACED ON</th>
                <th>VIA</th>
                <th>PAYMENY STATUS</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <Spinner />
                  </td>
                </tr>
              ) : orders.orders?.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    No orders found.
                  </td>
                </tr>
              ) : (
                orders.orders?.map((order) => (
                  <tr key={order._id}>
                    <td>
                      <Button color="link" onClick={() => handleOrderClick(order._id)}><strong>{order._id}</strong></Button>
                    </td>
                    <td>
                      {order.company?.name}
                    </td>
                    <td><span className={`badge ${getStatusBadgeClass(order.status)}`}>
                        {order.status.toUpperCase() || '?'}
                      </span></td>
                    <td>{order.kg_amount || 0} kg</td>
                    <td>{order.description}</td>
                    <td>{new Date(order.createdAt).toLocaleString()}</td>
                    <td><span className={`badge ${getViaBadgeClass(order.via)}`}>{order.via}</span></td>
                    <td><span className={`badge ${getPaymentStatusBadgeClass(order.paymentInfo.status)}`}>{order.paymentInfo.status}</span></td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AllOrdersPage;
