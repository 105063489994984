import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Card, CardBody, Alert } from 'reactstrap';
import { useNavigate, useOutletContext } from 'react-router-dom';
import http from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import { ModeContext } from '../../contexts/mode/ModeContext';
import OrderContext from '../../contexts/order/OrderContext';

import '../../assets/css/purchaseCarbonCredit.css';

const PurchaseCarbonCredit = () => {
    const [portfolios, setPortfolios] = useState([]);
    const [selectedPortfolio, setSelectedPortfolio] = useState('');
    const [tonnes, setTonnes] = useState(0);
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const unitPrice = 80.00;
    const { company } = useOutletContext();
    const totalPrice = tonnes * company.unitPrice;
    const navigate = useNavigate();

    const { isTestMode } = useContext(ModeContext);
    const { saveOrderData } = useContext(OrderContext);

    const handleCancel = () => {
        navigate('/profile/home');
    };

    const handleOrder = async () => {
        if (!selectedPortfolio.id || tonnes <= 0) {
            setError('Please select a portfolio and enter a valid amount of tonnes.');
            return;
        }

        try {
            const orderData = {
                portfolioId: selectedPortfolio.id,
                kg_amount: tonnes * 1000,
                description: description,
                unitPrice: company.unitPrice,
                totalPrice: totalPrice,
                companyId: company._id
            };

            const response = await http.post(`${API_ENDPOINTS.SUBMIT_ORDER}?isTest=${isTestMode}`, orderData);

            if (response.status === 201) {
                setSuccess('Order placed successfully!');
                setError('');
                navigate('/profile/offset');
            } else {
                setError('Failed to place order. Please try again.');
                setSuccess('');
            }
        } catch (error) {
            console.error('Error placing order:', error);
            setError('Error placing order. Please try again.');
            setSuccess('');
        }
    };

    const handlePortfolioChange = (e) => {
        const portfolio = e.target.value;
        setSelectedPortfolio(portfolio);
    };

    const handleCheckout = () => {
        const selectedPortfolioData = portfolios.find(portfolio => portfolio.name === selectedPortfolio);

        const orderData = {
            portfolio: { id: selectedPortfolioData._id, name: selectedPortfolioData.name },
            kg_amount: tonnes * 1000,
            description: description,
            unitPrice: company.unitPrice,
            totalPrice: totalPrice,
            companyId: company._id
        };

        saveOrderData(orderData);

        navigate('/profile/checkout');
    };

    useEffect(() => {
        const fetchPortfolios = async () => {
            try {
                const portfolioResponse = await http.get(`${API_ENDPOINTS.PORTFOLIO}?isTest=${isTestMode}`);
                setPortfolios(portfolioResponse.data.portfolios);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchPortfolios();
    }, [isTestMode]);

    return (
        <Container className="dashboard">
            <Row className="align-items-center justify-content-center">
                <Col>
                    <h1 className="mt-4">Purchase carbon credits</h1>
                    <Card className="checkout-card" style={{borderRadius: '20px'}}>
                        <CardBody>
                            <Form>
                                {error && <Alert color="danger">{error}</Alert>}
                                {success && <Alert color="success">{success}</Alert>}

                                <FormGroup>
                                    <Label for="tonnes">Tonnes CO₂e</Label>
                                    <Input
                                        type="number"
                                        name="tonnes"
                                        id="tonnes"
                                        value={tonnes}
                                        onChange={(e) => setTonnes(e.target.value)}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="portfolio">Select Portfolio</Label>
                                    <Input
                                        type="select"
                                        name="portfolio"
                                        id="portfolio"
                                        value={selectedPortfolio}
                                        onChange={handlePortfolioChange}
                                    >
                                        <option value="">Select a Portfolio</option>
                                        {portfolios?.map((portfolio) => (
                                            <option key={portfolio._id} value={portfolio.name}>
                                                {portfolio.name}
                                            </option>
                                        ))}
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="description">Provide description</Label>
                                    <Input
                                        type="text"
                                        name="description"
                                        id="description"
                                        placeholder="To be displayed on your purchase certificate"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </FormGroup>
                                <div className="pricing-info">
                                    <p>price/unit <span>SAR {company.unitPrice.toFixed(2)}</span></p>
                                    <p>Total price <span>SAR {totalPrice.toFixed(2)}</span></p>
                                </div>
                                <Button color="primary" block className="checkout-button" onClick={handleCheckout}>Proceed to checkout</Button>
                                <Button color="link" className="cancel-button" onClick={handleCancel}>Cancel</Button>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default PurchaseCarbonCredit;
