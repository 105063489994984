import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpageWESGS from "../../Layout/NavbarSubpageWESGS";
import { faEarthAmericas, faReceipt, faBullhorn, faTree, faCar, faDownload, faPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, FormGroup, Row, Container, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';

const TravelingInfo = () => {

    const { quizData, updateQuizData } = useContext(QuizContext);
    const [carbonFootprint, setCarbonFootprint] = useState(0);
    const [traveling, setTraveling] = useState(quizData.traveling ? quizData.traveling : '');
    const [travelingType, setTravelingType] = useState(quizData.travelingType ? quizData.travelingType : '');
    const [travelDistance, setTravelDistance] = useState(0);

    const navigate = useNavigate();
    const [totalQuestions, setTotalQuestions] = useState(4);
    const currentQuestion = 2;

    const handleNextClick = () => {

        updateQuizData({ traveling, travelingType, travelDistance, totalCF: carbonFootprint, tavelCF: carbonFootprint });

        navigate('/wesgs/commute-info');

    };

    const handleBackClick = () => {
        navigate('/wesgs/welcome');
    }

    const handleClickButton = (e) => {
        setTraveling(e.target.value);
    }

    const handletravelingType = (e) => {
        const TT = e.target.value
        setTravelingType(e.target.value);
        if (travelDistance) {
            calculateCarbonFootprint(TT, travelDistance);
        }
    }

    const handleTravelDistance = (e) => {
        const distance = parseFloat(e.target.value);
        setTravelDistance(distance);
        if (travelingType) {
            calculateCarbonFootprint(travelingType, distance);
        }
    }

    const getAirplaneEmissionFactor = (distance) => {
        if (distance <= 1500) {
            return 0.45; // Short-haul
        } else if (distance <= 3500) {
            return 0.40; // Medium-haul
        } else {
            return 0.38; // Long-haul
        }
    };

    const calculateCarbonFootprint = (mode, distance) => {
        let factor = 0;

        if (mode === 'airplane') {
            factor = getAirplaneEmissionFactor(distance);
        } else if (mode === 'car') {
            factor = 0.47; // High-end emissions for cars
        } else if (mode === 'train') {
            factor = 0.26; // High-end emissions for trains
        }

        const footprint = factor * distance;
        setCarbonFootprint(footprint.toFixed(2) * 2); // Round to 2 decimal places
    };


    return (
        <React.Fragment>
            <NavbarSubpageWESGS />
            <section className="bg-home5" id="home">
                <div className="bg-overlay"></div>
                <Container>
                    <div className="position-relative" style={{ zIndex: "1" }}>
                        <div className="progress-wrapper sticky-top mb-3">
                            <div className="d-flex justify-content-between mb-1">
                                <span>{currentQuestion} / {totalQuestions}</span>
                            </div>
                            <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
                        </div>
                        <Row className="align-items-center">
                            <Col lg={6}>
                                <h2>Are you traveling to Riyadh to attend Wolrd ESG Summit?</h2>
                                <div>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <label>
                                                    <Form.Check
                                                        type="radio"
                                                        value="yes"
                                                        checked={traveling === 'yes'}
                                                        onChange={(e) => handleClickButton(e)}
                                                        label="Yes"
                                                    />
                                                </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <FormGroup>
                                                <label>
                                                    <Form.Check
                                                        type="radio"
                                                        value="no"
                                                        checked={traveling === 'no'}
                                                        onChange={(e) => handleClickButton(e)}
                                                        label="No"
                                                    />
                                                </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {traveling === 'yes' && (
                                        <Row>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <label htmlFor="travelingTypeSelect">How are you traveling to Riyadh?</label>
                                                    <Form.Select
                                                        id="travelingTypeSelect"
                                                        value={travelingType}
                                                        onChange={(e) => handletravelingType(e)}
                                                    >
                                                        <option value="">Select an option</option>
                                                        <option value="airplane">Air Plane</option>
                                                        <option value="car">Car</option>
                                                        <option value="train">Train</option>
                                                    </Form.Select>
                                                </FormGroup>
                                                <FormGroup>
                                                    <label htmlFor="travelingTypeSelect">How many kilometers is your one way trip?</label>
                                                    <input
                                                        type="number"
                                                        value={travelDistance}
                                                        onChange={(e) => handleTravelDistance(e)}
                                                        className="form-control"
                                                        placeholder="Trip distance in KG"
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                                <div className="mt-2">
                                    <button onClick={handleBackClick} className="btn btn-primary me-4">
                                        Back
                                    </button>
                                    <button onClick={handleNextClick} disabled={!traveling || (traveling === 'yes' && !travelingType)} className="btn btn-primary">
                                        Next
                                    </button>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="metric-detail">
                                    
                                    <h3>Your Carbon Footprint:</h3>
                                    <h4>{carbonFootprint} kg CO₂e</h4>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col lg={6}>
                            <div className="me-4">
                                <h6 className="sub-title mb-1"><i class="bi bi-question-circle"></i>Why are we asking this?</h6>
                                <p className="text-muted fs-17">
                                    Your mode of travel and the distance of your trip will directly impact your carbon footprint.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default TravelingInfo;
