import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const ProfileTopNav = ({ user, signOut, company }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [initials, setInitials] = useState('');

    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const navigate = useNavigate();


    const getInitials = (name) => {
        if (!name) return '';
        return name
            .split(' ') // Split the name into an array of words
            .map(word => word[0]?.toUpperCase()) // Get the first character of each word and convert it to uppercase
            .join(''); // Join the initials together
    }

    useEffect(() => {
        setInitials(getInitials(user?.name)); // Set initials when user.name changes
    }, [user?.name]);


    const handlePurchaseClick = () => {
        navigate('/profile/purchase');
    }
    const handleBilling = () => {
        navigate('/profile/billing');
    }
    const handleAccount = () => {
        navigate('/profile/account');
    }
    const handleSignOut = () => {
        signOut();
        navigate("/login");
    }
    return (
        <div style={styles.navbar}>
            {/* Continue Purchase Button */}
            {/* <button  color="primary" onClick={handlePurchaseClick} style={styles.purchaseButton}>Purchase credits</button> */}

            {/* Profile Dropdown */}
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle
                    tag="div"
                    style={styles.profileToggle}
                    data-toggle="dropdown"
                >
                    <div style={styles.profileCircle}>{initials}</div>
                    <span style={styles.dropdownIcon}>▼</span>
                </DropdownToggle>
                <DropdownMenu right style={styles.dropdownMenu}>
                    <DropdownItem onClick={handleBilling}>
                        <i className="mdi mdi-credit-card" style={styles.icon} ></i>Billing 
                    </DropdownItem>
                    <DropdownItem onClick={handleAccount}>
                        <i className="mdi mdi-account" style={styles.icon}></i>Account 
                    </DropdownItem>
                    <DropdownItem onClick={handleSignOut}>
                        <i className="mdi mdi-logout" style={styles.icon} ></i> Sign out
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

const styles = {
    navbar: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "10px 20px",
        backgroundColor: "#fff",
        borderBottom: "1px solid #eaeaea",
    },
    purchaseButton: {
        backgroundColor: "#05bd93",
        color: "#fff",
        border: "none",
        borderRadius: "20px",
        padding: "10px 20px",
        fontSize: "14px",
        cursor: "pointer",
        marginRight: "20px",
    },
    profileToggle: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
    },
    profileCircle: {
        backgroundColor: "#000000",
        color: "#fff",
        borderRadius: "50%",
        width: "32px",
        height: "32px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: "bold",
        marginRight: "8px",
    },
    dropdownIcon: {
        fontSize: "12px",
        color: "#333",
    },
    dropdownMenu: {
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        width: "250px",
    },
    profileInfo: {
        display: "flex",
        alignItems: "center",
        padding: "15px",
        borderBottom: "1px solid #eaeaea",
    },
    profileName: {
        margin: 0,
        fontSize: "14px",
        fontWeight: "bold",
    },
    profileEmail: {
        margin: 0,
        fontSize: "12px",
        color: "#666",
    },
    icon: {
        marginRight: "10px",
    },
};

export default ProfileTopNav;
