import React, { useState, useContext } from 'react';
import { QuizContext } from '../../contexts/quiz/QuizContext';
import NavbarSubpageWESGS from "../../Layout/NavbarSubpageWESGS";
import { Col, Container, Row, Progress } from "reactstrap";
import { useNavigate } from 'react-router-dom';

const DaysAttending = () => {
    const { quizData, updateQuizData } = useContext(QuizContext);
    const [numberOfDays, setnumberOfDays] = useState(quizData.numberOfDays ? quizData.numberOfDays : 1);
    const [tavelCF, settavelCF] = useState(quizData.tavelCF ? quizData.tavelCF : 0);
    const [commuteCF, setcommuteCF] = useState(quizData.commuteCF ? quizData.commuteCF : 0);
    const navigate = useNavigate()
    const [totalQuestions, setTotalQuestions] = useState(4);
    const currentQuestion = 4; // This should be dynamically updated based on the current question index
    const [commuteDistance, setCarbonFootprint] = useState(quizData.commuteDistance);
    const [totalCF, setTotalCF] = useState(quizData.totalCF);

    const handleNextClick = () => {
        if (numberOfDays) {
            updateQuizData({ numberOfDays });
            navigate('/wesgs/summary');
        }
    };

    const handleBackClick = () => {
        navigate('/wesgs/commute-info');
    }

    const handlNumberOfDaysChange = (e) => {
        const nd = e.target.value;
        setnumberOfDays(e.target.value);
        //setCarbonFootprint(totalCF * nd);
    }

    return (
        <React.Fragment>
            <NavbarSubpageWESGS />
            <section className="bg-home5" id="home">
                <div className="bg-overlay"></div>
                <Container>
                    <div className="position-relative" style={{ zIndex: "1" }}>
                        <div className="progress-wrapper sticky-top mb-3">
                            <div className="d-flex justify-content-between mb-1">
                                <span>{currentQuestion} / {totalQuestions}</span>
                            </div>
                            <Progress value={(currentQuestion / totalQuestions) * 100} color="primary" />
                        </div>
                        <Row className="align-items-center">
                            <Col lg={6}>

                                <h2>How many days will you be attending the World ESG Summit 2025?</h2>
                                {/* <p className="text-muted fs-17">Tip: Your HR or management team would know this</p> */}
                                <input
                                    type="number"
                                    value={numberOfDays}
                                    onChange={(e) => handlNumberOfDaysChange(e)}
                                    className="form-control"
                                    placeholder="Number of Days"
                                />
                                <div className="mt-2">
                                    <button onClick={handleBackClick} className="btn btn-primary me-4">
                                        Back
                                    </button>
                                    <button onClick={handleNextClick} disabled={!numberOfDays} className="btn btn-primary">
                                        Next
                                    </button>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="metric-detail">

                                    <h3>Your Carbon Footprint:</h3>
                                    <h4>{numberOfDays > 0 ? (parseFloat(tavelCF) + (parseFloat(commuteCF) * parseFloat(numberOfDays))).toFixed(2) : parseFloat(totalCF).toFixed(2)} kg CO₂e</h4>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <Container className="mt-4">
                    <Row>
                        <Col lg={6}>
                            <div className="me-4">
                                <h6 className="sub-title mb-1"><i class="bi bi-question-circle"></i>Why are we asking this?</h6>
                                <p className="text-muted fs-17">
                                    We need to know the total number of days you'll be attending the summit, as it directly impacts your overall carbon footprint.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default DaysAttending;
