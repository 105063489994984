import React, { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "framer-motion"; // Hook to detect when components come into view
import NavBarAR from "../../Layout/NavbarAR";
import SectionAR from "./SectionAR";
import ServicesAR from "../../components/ServicesAR";
import About from "../../components/About";
import ProcessAR from "../../components/ProcessAR";
import Counter from "../../components/Counter";
import Testimonial from "../../components/Testimonial";
import Team from "../../components/Team";
import Portfolio from "../../components/Portfolio";
import PricingAR from "../../components/PricingAR";
import IntegrationAR from "../../components/IntegrationAR";
import BlogAR from "../../components/BlogAR";
import CTAAR from "../../components/CTAAR";
import ContactAR from "../../components/ContactAR";
import FooterAR from "../../Layout/FooterAR";
import FooterAlt from "../../Layout/FooterAlt";
import BackToTop from "../../components/BackToTop";
import APIFeaturesAR from "../../components/APIFeaturesAR";
import ProductsSectionAR from "../../components/ProductsSectionAR";
import ImpactSectionAR from "../../components/ImpactSectionAR";
import ConsultingServicesAr from "../../components/ConsultingServicesAR";
import Registries from "../../components/Registeries";
const Layout5 = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Check the user's color scheme preference
    const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    setIsDarkMode(darkModeMediaQuery.matches);

    // Add event listener to handle changes in the color scheme
    const handleChange = (e) => setIsDarkMode(e.matches);
    darkModeMediaQuery.addEventListener("change", handleChange);

    // Cleanup the event listener on component unmount
    return () => darkModeMediaQuery.removeEventListener("change", handleChange);
  }, []);

  // Animation variants
  const fadeIn = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const slideInFromLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  const slideInFromRight = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.8 } },
  };

  const slideInFromBottom = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  // Component to handle animations on scroll
  const AnimatedSection = ({ children, variants }) => {
    const controls = useAnimation();
    const ref = React.useRef();
    const inView = useInView(ref, { triggerOnce: true, threshold: 0.2 });

    useEffect(() => {
      if (inView) {
        controls.start("visible");
      }
    }, [inView, controls]);

    return (
      <motion.div
        ref={ref}
        initial="hidden"
        animate={controls}
        variants={variants}
      >
        {children}
      </motion.div>
    );
  };

  return (
    <React.Fragment>
      <NavBarAR isDark={isDarkMode} />
      <AnimatedSection variants={fadeIn}>
        <SectionAR />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromBottom}>
        <Registries />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromLeft}>
        <ServicesAR />
      </AnimatedSection>
       <AnimatedSection variants={slideInFromRight}>
        <About />
      </AnimatedSection>
      <AnimatedSection variants={fadeIn}>
        <ProductsSectionAR />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromBottom}>
        <ConsultingServicesAr />
      </AnimatedSection>
      {/* <AnimatedSection variants={slideInFromLeft}>
        <ProcessAR />
      </AnimatedSection>
      <AnimatedSection variants={fadeIn}>
        <APIFeaturesAR />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromRight}>
        <ProductsSectionAR />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromBottom}>
        <ImpactSectionAR />
      </AnimatedSection> */}
      <AnimatedSection variants={slideInFromLeft}>
        <PricingAR />
      </AnimatedSection>
      {/* <AnimatedSection variants={fadeIn}>
        <CTAAR />
      </AnimatedSection> */}
      
      <AnimatedSection variants={slideInFromRight}>
        <ContactAR />
      </AnimatedSection>
      <AnimatedSection variants={slideInFromRight}>
        <BlogAR />
      </AnimatedSection>
      <FooterAR />
      <FooterAlt />
      <BackToTop />
    </React.Fragment>
  );
};


export default Layout5;
