import React, { useState, useEffect } from "react";
import { Container, Navbar, NavbarBrand, Nav, NavItem, NavLink, Button } from "reactstrap";
import { Link } from "react-router-dom";

// استيراد الصور
import logoLight from "../assets/images/nz-logo.png";
import logoDark from "../assets/images/nz-logo-dk.png";

const NavBar = ({ isDark }) => {
  const [navClass, setNavClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    const scrollUp = document.documentElement.scrollTop;
    setNavClass(scrollUp > 50 ? "nav-sticky" : "");
  }

  return (
    <Navbar expand="lg" className={`custom-navbar ${navClass}`} style={{
      background: 'linear-gradient(to bottom, white,rgb(243 255 250))', /* أبيض إلى أخضر فاتح */
      borderBottom: '1px solid rgba(0, 0, 0, 0.1);' /* خيار إضافي: حدود خفيفة */
    }} dir="rtl"> {/* تفعيل الاتجاه من اليمين لليسار */}
      <Container className="d-flex justify-content-between align-items-center">

        {/* عناصر القائمة اليمنى */}
        <Nav className="d-flex align-items-center">
          <NavItem>
            <NavLink href="/" className="nav-item-link">
              <Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>EN</Button>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#process" className="nav-item-link">
              <Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>العملية</Button>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#pricing" className="nav-item-link">
              <Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>التسعير</Button>
            </NavLink>
          </NavItem>
        </Nav>

        {/* شعار في المنتصف */}
        <NavbarBrand className="center-logo">
          <Link to="/">
            <img src={isDark ? logoDark : logoLight} alt="الشعار" className="nav-logo" style={{ height: '100px' }} />
          </Link>
        </NavbarBrand>

        {/* عناصر القائمة اليسرى */}
        <Nav className="d-flex align-items-center">
          <NavItem>
            <NavLink href="#blog" className="nav-item-link">
              <Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>المدونة</Button>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/terms" className="nav-item-link">
              <Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>الشروط</Button>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#contact" className="contact-button">
              <Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}>اتصل بنا</Button>
            </NavLink>
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default NavBar;
