import React, { createContext, useState, useEffect } from 'react';

export const QuizContext = createContext();

export const QuizProvider = ({ children }) => {
  // Load data from sessionStorage if it exists
  const storedData = sessionStorage.getItem('quizData');
  const [quizData, setQuizData] = useState(storedData ? JSON.parse(storedData) : {});

  const updateQuizData = (newData) => {
    setQuizData((prevData) => {
      const updatedData = { ...prevData, ...newData };
      
      // Save updated data to sessionStorage
      sessionStorage.setItem('quizData', JSON.stringify(updatedData));
      
      return updatedData;
    });
  };

  const clearQuizData = () => {
    setQuizData({}); // Clear state
    sessionStorage.removeItem('quizData'); // Clear sessionStorage
  };

  // Keep sessionStorage in sync when quizData changes
  useEffect(() => {
    sessionStorage.setItem('quizData', JSON.stringify(quizData));
  }, [quizData]);

  return (
    <QuizContext.Provider value={{ quizData, updateQuizData, clearQuizData }}>
      {children}
    </QuizContext.Provider>
  );
};
