import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import UserContext from "../../contexts/user/UserContext";
import "../../assets/css/HomeSection.css";

// Import background images
import star from "../../assets/images/star.png";
import cyl from "../../assets/images/cyl.png";
import arch from "../../assets/images/arch.png";

const Section = () => {
  const { user } = useContext(UserContext);

  return (
    <section className="home-section">
      {/* Background Images */}
      

      <Container>
      <img src={star} alt="Star" className="bg-image star" />
      <img src={cyl} alt="Cylinder" className="bg-image cyl" />
      <img src={arch} alt="Arch" className="bg-image arch" />
        <Row className="align-items-center">
          <Col lg={6} className="text-content">
            <h1>
              Integrate climate actions into your <span className="bold">products</span>
            </h1>
            <p className="description">
              c-mass offers a bespoke solution for programmatic carbon footprint estimation and carbon credit access,
              enabling seamless API integration into your offerings for a customized and efficient experience.
            </p>
            <div className="button-group">
              {user ? (
                <Link to="/profile/home" className="btn btn-primary" style={{ borderRadius: '20px', padding: '6px 14px' }}>
                  Go to my dashboard
                </Link>
              ) : (
                <>
                  <Button color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px', marginRight: '5px' }}><Link to="/signup" style={{color: 'white'}}>Signup</Link></Button>
                  <Button outline color="primary" className="rotate-button" style={{ borderRadius: '20px', padding: '6px 14px' }}><Link to="/login" style={{color: 'black'}}>Login</Link></Button>
                </>
              )}
            </div>
          </Col>
        </Row>
        
      </Container>
      
    </section>
  );
};

export default Section;
