import React from "react";
import { Link } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";
import "../assets/css/BlogSection.css"; // تأكد من إنشاء ملف CSS المناسب

// صور المقالات
import img1 from "../assets/images/blog/1730113501007.jpg";
import img2 from "../assets/images/blog/img-2.png";
import img3 from "../assets/images/blog/img-5.png";

// الشعارات
import img5 from "../assets/images/nz-logo-dk.png";
import carbonOffsetLogo from "../assets/images/feature/CO_certification_logo.jpg";
import cmassLogo from "../assets/images/nz-logo.png";

import arch from "../assets/images/arch.png";

// بيانات المقال الرئيسي
const featuredBlog = {
  imgsrc: img1,
  title: "COC و c-mass يعلنان عن شراكة جديدة!",
  subtitle:
    "تهدف c-mass و Carbon Offset Certification إلى تعزيز الابتكار في شهادات الكربون والامتثال البيئي في المملكة العربية السعودية.",
  date: "20 أغسطس 2022",
  url: "https://www.linkedin.com/posts/c-mass_c-mass-and-carbon-offset-certification-aim-ugcPost-7257461302762106881-8GtS?utm_source=share&utm_medium=member_desktop",
};

// بيانات المقالات الإضافية
const articleBlogs = [
  {
    imgsrc: img2,
    title: "كيفية تعزيز مبيعات الأسواق وفقاً لإرشادات GCOM",
    date: "20 أغسطس 2022",
    url: "https://gcom.cdmdna.gov.sa/Downloads/KSA-GCOM-GUIDELINE.pdf",
  },
  {
    imgsrc: img3,
    title: "السعودية تتخذ خطوات جريئة نحو مستقبل أكثر خضرة",
    date: "20 أغسطس 2022",
    url: "https://www.arabnews.com/node/2470011/business-economy",
  },
  {
    imgsrc: img5,
    title: "السعودية تتخذ خطوات جريئة نحو الحياد الكربوني",
    date: "20 أغسطس 2022",
    url: "https://www.arabnews.com/node/2470011/business-economy",
  },
];

const Blog = () => {
  return (
    <section className="blog-section" id="blog" style={{ direction: "rtl" }}>
      <img src={arch} alt="كائن ثلاثي الأبعاد" className="contact-object" style={{ width: "100px" }} />
      <Container>
        <Row className="justify-content-center">
          <Col lg={7}>
            <div className="header-title text-center mb-4">
              <h3>أحدث المقالات والأخبار</h3>
            </div>
          </Col>
        </Row>

        <Row>
          {/* المقال الرئيسي على اليسار */}
          <Col lg={7}>
            <div className="featured-blog">
              <div className="blog-header">
                <img src={carbonOffsetLogo} alt="تعويض الكربون" className="blog-logo" />
                <img src={cmassLogo} alt="c-mass" className="blog-logo" />
              </div>
              <Link to={featuredBlog.url} className="blog-image-wrapper">
                <img src={featuredBlog.imgsrc} className="blog-image" alt="" />
              </Link>
              <div className="blog-content">
                <p className="blog-date">{featuredBlog.date}</p>
                <h5 className="blog-title">{featuredBlog.title}</h5>
                <p className="blog-subtitle">{featuredBlog.subtitle}</p>
              </div>
            </div>
          </Col>

          {/* المقالات الجانبية على اليمين */}
          <Col lg={5}>
            <div className="article-list">
              {articleBlogs.map((article, idx) => (
                <Link to={article.url} className="article-item" key={idx}>
                  <img src={article.imgsrc} alt={article.title} className="article-image" />
                  <div className="article-content">
                    <p className="article-date">{article.date}</p>
                    <h6 className="article-title">{article.title}</h6>
                  </div>
                </Link>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Blog;
