import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useParams } from "react-router-dom";
import { ModeContext } from '../../contexts/mode/ModeContext';
import http from "../../frameworks/basic-rest/http";
import phttp from "../../frameworks/basic-rest/http";
import { API_ENDPOINTS } from "../../frameworks/basic-rest/api-endpoints";
import "../../assets/css/orderPage.css"; // Create and import your CSS file
import Spinner from "react-bootstrap/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLeaf, faWeightScale,faProjectDiagram, faMapMarkerAlt, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import cMassLogo from '../../assets/images/nz-logo.png'; // Replace with your C-Mass logo path
import logo from '../../assets/images/nz-logo.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import ct from '../../assets/images/ct.png';
import QRCode from 'qrcode';

import star from "../../assets/images/star.png";
import cyl from "../../assets/images/cyl.png";
import arch from "../../assets/images/arch.png";

const PublicOrderPage = () => {
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const { isTestMode } = useContext(ModeContext);

  const generateCertificate = async () => {

    try {
        const projectIds = order.projectRecords.map(record => record.projectId);
        if (!projectIds || projectIds.length === 0) {
            console.error('No projects found');
            return;
        }
        // Fetch project records by their IDs

        // Extract project IDs from the project records

        // Fetch projects using the project IDs
        const projectsResponse = await phttp.post(`${API_ENDPOINTS.PROJECTS_BY_IDS}?isTest=${isTestMode}`, { projectIds });

        // Store the project details
        //setProjects(projectsResponse.data.projects);

        const doc = new jsPDF();

        // Load the background image (optional)
        const img = new Image();
        img.src = ct;

        let imgWidth, imgHeight;

        // Wait for the image to load
        await new Promise(resolve => {
            img.onload = () => {
                // Set image dimensions
                imgWidth = img.width;
                imgHeight = img.height;

                // Set the PDF page size to match the image dimensions
                doc.internal.pageSize.width = imgWidth * 0.264583; // Convert px to mm
                doc.internal.pageSize.height = imgHeight * 0.264583;

                // Add the image, centering it on the page
                doc.addImage(img, 'PNG', 0, 0, imgWidth * 0.264583, imgHeight * 0.264583);

                resolve();
            };
        });

        doc.setFont('Helvetica');
        doc.setFontSize(100);
        doc.setTextColor('#222222');

        doc.text(`${order.createdOn.split('T')[0]}`, 250, 58, { align: "center" });
        if(order?.customer) {
          doc.text(`${order?.customer}`, 120, 338, { align: "left" });
        }
        else {
          doc.text(`${order.company?.name}`, 120, 338, { align: "left" });
        }
        doc.text(`${order.creditsPurchased} kg of CO₂e`, 120, 465, { align: "left" });

        let projs_name = '';
        let projs_type = '';
        let proj_registry = '';
        let proj_location = '';

        projectsResponse.data.projects.forEach((project, index) => {
            if ((index + 1) == projectsResponse.data.projects.length) {
                projs_name += `${project.name}`;
                projs_type += `${project.type}`;
                proj_registry += `${project.carbonRegistry.name}`;
                proj_location += `${project.location}`;
            }
            else {
                projs_name += `${project.name}, `;
                projs_type += `${project.type}, `;
                proj_registry += `${project.carbonRegistry.name}, `;
                proj_location += `${project.location}, `;
            }

        });
        doc.text(`${projs_name}`, 70, 595, { align: "left" });
        doc.text(`${projs_type}`, 70, 725, { align: "left" });
        doc.text(`${proj_registry}`, 840, 725, { align: "left" });
        doc.text(`${proj_location}`, 70, 855, { align: "left" });
        doc.text(`c-mass`, 840, 855, { align: "left" });

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(200);
        doc.setTextColor('#2cc9a2');
        doc.text(`${order.creditsPurchased}`, 500, 945, { align: "left" });

        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(80);
        doc.setTextColor('#0096FF');
        doc.textWithLink(`${order.orderNumber}`, 215, 990, { url: 'http://www.google.com' });

        doc.setTextColor('#222222');
        doc.text(`${proj_registry}`, 250, 1030, { align: "left" });

        // Generate the QR code and add it to the PDF 
        const qrDataUrl = await QRCode.toDataURL(`https://c-mass.co/order-detail/${order._id}`, { width: 100 });
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(15);
        doc.setTextColor('#222222');



        // // Position text and QR code to fit within the image dimensions
        // doc.addImage(qrDataUrl, 'PNG', imgWidth * 0.5 * 0.264583 - 12.5, imgHeight * 0.85 * 0.240783, 190, 190);
        // Position text and QR code to fit within the image dimensions
        doc.addImage(qrDataUrl, 'PNG', imgWidth * 0.5 * 0.364583 - 12.5, imgHeight * 0.85 * 0.240783, 190, 190);

        // Add a new page if this is not the last code

        // Save the PDF with all promo codes in one file
        doc.save('cc--certificate.pdf.pdf');

    } catch (error) {

    }

};

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await http.get(`${API_ENDPOINTS.PUBLIC_ORDER_DETAILS}/${orderId}?isTest=${isTestMode}`);
        setOrder(response.data.order);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [orderId, isTestMode]);

  const handleCertificate = () => {
    generateCertificate();
}

  return (
    <div className="-section">
      <img src={star} alt="Star" className="bg-image star" />
            <img src={cyl} alt="Cylinder" className="bg-image cyl" />
            <img src={arch} alt="Arch" className="bg-image arch" />
      {loading ? <Container><Spinner /></Container> : (
        
        <Container >
          <Row className="order-header mt-4">
            <Col md="6">
            <img src={cMassLogo} alt="Powered by C-Mass" style={{ maxWidth: '80px', marginTop: '5px' }} />
              <h4 className="order-id">Order ID: <span className="order-id-value">{order.orderNumber}</span></h4>
              <h1 className="order-title">Order Details</h1>
              <ul className="order-details-list">
                <li><strong>Placed On:</strong> {new Date(order.createdAt).toLocaleString()}</li>
                <li><strong>Amount:</strong> {order.creditsPurchased} kg</li>
                <li><strong>Portfolio:</strong> {order.portfolio?.name}</li>
                <li><strong>Purchased By:</strong> {order?.customer ? order?.customer : order.company?.name}</li>
              </ul>
              <Button color="link" className="view-certificate-btn" onClick={handleCertificate}>View certificate</Button>
            </Col>
          </Row>

          <Row className="fulfilled-section">
            <Col>
              <h2 className="fulfilled-title">Fulfilled With</h2>
              <div className="fulfilled-projects-container">
                              {order.projectRecords?.map((projectRecord, index) => (
                                <div key={index} className="fulfilled-card">
                                  <h4 className="project-name-details">
                                    <FontAwesomeIcon icon={faProjectDiagram} /> {projectRecord.projectId?.name}
                                  </h4>
                                  <ul className="project-details">
                                    <li>
                                      <FontAwesomeIcon icon={faWeightScale} /> {(projectRecord.delta).toFixed(2)} kg
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faMapMarkerAlt} /> {projectRecord?.projectId?.location}
                                    </li>
                                    <li>
                                      <FontAwesomeIcon icon={faCheckCircle} /> {projectRecord?.projectId?.carbonRegistry?.name} ID:
                                      <a href={`https://registry.verra.org/app/projectDetail/VCS/${projectRecord?.projectId?.carbonRegistry?.id}`} target="_blank" rel="noopener noreferrer">
                                        {projectRecord?.projectId?.carbonRegistry?.id}
                                      </a>
                                    </li>
                                  </ul>
                                  <p className="project-type-details">
                                    <FontAwesomeIcon icon={faLeaf} /> {projectRecord.projectCategoryId?.name}
                                  </p>
                                </div>
                              ))}
                            </div>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default PublicOrderPage;
